import { ReactComponent as TableDownArrow } from "../../assets/images/table-down-arrow.svg";
import { ReactComponent as TableUpArrow } from "../../assets/images/table-up-arrow.svg";

const TableSortArrowsBlock = ({ noSort, handleSortChange, itemName, activeSortedItem }) => {
  if (noSort) {
    return null;
  }

  return (
    <div className="table-sort-arrows-container">
      <TableUpArrow
        onClick={handleSortChange.bind(null, itemName, "asc", "up")}
        className={`arrow ${
          activeSortedItem?.name === itemName && activeSortedItem?.direction === "asc" && "active"
        }`}
      />
      <TableDownArrow
        onClick={handleSortChange.bind(null, itemName, "desc", "down")}
        className={`arrow ${
          activeSortedItem?.name === itemName && activeSortedItem?.direction === "desc" && "active"
        }`}
      />
    </div>
  );
};

export default TableSortArrowsBlock;
