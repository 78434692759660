import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { formatMoney, formatNumber } from "../../utils";
import Avatar from "../common/Avatar";
import BlurText from "../common/BlurText";
import { getPayAdjustment } from "../../helpers/helpers";

const LeaderboardTableRaw = ({ userData }) => {
  const {
    last_position,
    position,
    employee,
    number_of_stops,
    profit,
    stops_per_day,
    total_early_late_pickup,
    total_miss_pickup_window,
    total_code_85,
    total_ils_percentage,
    total_dna,
    packages_per_day
  } = userData;

  const color = profit < 1000 ? "red-item" : profit < 2500 ? "green-item" : "yellow-item";

  return (
    <tr>
      <td className="sticky-col first-col">
        <div className="leaderboard-ranked">
          {position}
          {position - last_position !== 0 && (
            <div
              className={`leaderboard-ranked-last ${
                position - last_position < 0 ? "green" : "red"
              }`}>
              {position - last_position > 0 ? <ArrowDropDown /> : <ArrowDropUp />}{" "}
              {position - last_position < 0 ? last_position - position : position - last_position}
            </div>
          )}
        </div>
      </td>
      <td className="sticky-col second-col">
        <div className="statement-first-block leaderboard-row-avatar">
          <Avatar
            variant="circular"
            className="statement-avatar"
            alt={employee.first_name}
            src={employee.profile_image}
            withBadge
            badgeType={getPayAdjustment(employee)}
          />
          <div className="statement-name-block">
            <div>
              {employee.first_name} <BlurText>{employee.last_name}</BlurText>
            </div>

            <div className="statement-company">{employee.company_position_name}</div>
          </div>
        </div>
      </td>
      <td>{formatNumber(total_early_late_pickup)}</td>
      <td>{formatNumber(total_miss_pickup_window)}</td>
      <td>{formatNumber(total_code_85)}</td>
      <td>{formatNumber(total_ils_percentage)}</td>
      <td>{formatNumber(total_dna)}</td>
      <td>{formatNumber(stops_per_day)}</td>
      <td>{formatNumber(packages_per_day)}</td>
      <td>{formatNumber(number_of_stops)}</td>
      <td>
        <samp className={color}>{formatMoney(profit)}</samp>
      </td>
    </tr>
  );
};

export default LeaderboardTableRaw;
