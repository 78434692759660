import OrganizationsTableRaw from "./OrganizationsTableRaw";

const OrganizationsTable = ({
  organizationsList,
  handleImpersonateOrganizationsChange,
  handleImpersonateOrganizationMemberChange,
  setIsDeleted
}) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Organization</th>
          <th>Email</th>
          <th>Subscribed</th>
          <th>Registration Date</th>
          <th>Last Seen</th>
          <th>Trial Date</th>
          <th>Companies</th>
          <th>CSA</th>
          <th>Options</th>
        </tr>
      </thead>

      <tbody>
        {organizationsList.map((item) => (
          <OrganizationsTableRaw
            key={item.id}
            organizationData={item}
            handleImpersonateOrganizationsChange={handleImpersonateOrganizationsChange}
            handleImpersonateOrganizationMemberChange={handleImpersonateOrganizationMemberChange}
            setIsDeleted={setIsDeleted}
          />
        ))}
      </tbody>
    </table>
  );
};

export default OrganizationsTable;
