import { MoreVert, VerifiedOutlined, ThumbDownAltOutlined } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";

const PendingRequestsTableRaw = ({ requestsData, handleApprove, handleDecline }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleOpenDeclineModal = () => {
    handleClose();
    handleDecline(requestsData);
  };

  const handleOpenApproveModal = () => {
    handleClose();
    handleApprove(requestsData);
  };

  return (
    <tr>
      <td>{requestsData.name}</td>
      <td>{requestsData.email}</td>
      <td>{requestsData.id}</td>
      <td>{dayjs(requestsData.created_at).format("MMM DD, YYYY")}</td>

      <td>
        <button className="document-more-btn" onClick={handleOpen}>
          <MoreVert className="document-more-btn-icon" />
        </button>

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className="more-menu">
          <MenuItem onClick={handleOpenApproveModal}>
            <button className="document-more-btn menu-item">
              <VerifiedOutlined sx={{ color: "#344054" }} />
            </button>
            Approve
          </MenuItem>
          <MenuItem onClick={handleOpenDeclineModal}>
            <button className="document-more-btn menu-item">
              <ThumbDownAltOutlined sx={{ color: "#344054" }} />
            </button>
            Decline
          </MenuItem>
        </Menu>
      </td>
    </tr>
  );
};

export default PendingRequestsTableRaw;
