import TableSortArrowsBlock from "../common/TableSortArrowsBlock";
import RunPayrollTableRow from "./RunPayrollTableRow";

const RunPayrollTable = ({ payrolls, handleSortChange, activeSortedItem }) => {
  return (
    <div className="payroll-table">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              <div className="leaderboard-top-table-header-item">
                Date Uploaded{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  itemName="created_at"
                  activeSortedItem={activeSortedItem}
                />
              </div>
            </th>
            <th>
              <div className="leaderboard-top-table-header-item">
                Last Updates{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  itemName="updated_at"
                  activeSortedItem={activeSortedItem}
                />
              </div>
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {payrolls.map((el, index) => (
            <RunPayrollTableRow key={el.id} itemData={el} index={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RunPayrollTable;
