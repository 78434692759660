import "../../assets/css/modal.css";
import { Modal, TextField } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import Loading from "../common/Loading";
import { ReactComponent as AddCircle } from "../../assets/images/settings/add-circle.svg";
import { ReactComponent as Edit } from "../../assets/images/settings/edit.svg";

const styles = {
  error: {
    "& div input": {
      borderColor: "red",
      borderLeft: "1px solid red !important"
    }
  }
};

const schema = yup.object().shape({
  adp_co_code: yup.string().required("Co code is required!")
});

const ADPCompanyModal = ({ open, handleClose, editedCompany, handleConfirm, isLoading }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      adp_co_code: ""
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (editedCompany && editedCompany.adp_co_code) {
      setValue("adp_co_code", editedCompany.adp_co_code, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [editedCompany]);

  const handleCurClose = () => {
    handleClose();
    reset();
  };

  const onSubmit = (values) => handleConfirm(values, handleCurClose);

  return (
    <Modal open={open} onClose={handleCurClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper">
          <div className="confirm-modal-close-wrapper" onClick={handleCurClose}>
            <CloseIcon />
          </div>

          <div className="confirm-modal-icon-wrapper recalculate">
            {editedCompany ? (
              <Edit className="confirm-modal-icon company" />
            ) : (
              <AddCircle className="confirm-modal-icon company" />
            )}
          </div>

          <div>{editedCompany ? editedCompany.name : "Add Company"}</div>

          <form className="onboard-form company-add-modal">
            <div className="form-item">
              <label className={errors.adp_co_code?.message && "error"}> Co code *</label>
              <div className="input-box">
                <TextField
                  sx={errors.adp_co_code?.message && styles.error}
                  fullWidth
                  type="text"
                  placeholder="Enter Co code"
                  name="adp_co_code"
                  required
                  {...register("adp_co_code")}
                  error={touchedFields.adp_co_code && !!errors.adp_co_code?.message}
                  helperText={errors.adp_co_code?.message}
                />
              </div>
            </div>
          </form>

          <div className="confirm-modal-btn-wrapper">
            <button className="confirm-modal-btn cancel company-btn" onClick={handleCurClose}>
              Cancel
            </button>
            <button
              className="confirm-modal-btn company company-btn"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}>
              {isLoading ? <Loading /> : editedCompany ? "Save changes" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ADPCompanyModal;
