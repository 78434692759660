import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import AddOrEditPositionModal from "../modals/AddOrEditPositionModal";
import axios from "../../axios";
import { actions as toastActions } from "../../store/toast/toast.reducers";
import Loading from "./../common/Loading";
import SettingsPreferencesTable from "./preferences/SettingsPreferencesTable";

const SettingsPreferences = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isUpdateNeed, setIsUpdateNeed] = useState(false);

  const [editPosition, setEditedPosition] = useState();

  const [loading, setLoading] = useState(false);
  const [positionsList, setPositionsList] = useState([]);

  useEffect(() => {
    if (!isUpdateNeed) {
      fetchPositionList();
    }

    if (isUpdateNeed) {
      setIsUpdateNeed(false);
    }
  }, [isUpdateNeed]);

  const fetchPositionList = () => {
    setLoading(true);

    axios
      .get("/settings/positions")
      .then((response) => {
        if (response.success) {
          setPositionsList(response.data.positions);
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEdit = (position) => setEditedPosition(position);
  const handleEditClose = () => setEditedPosition();

  return (
    <div className="my-profile-dis settings-tabs">
      <div className="top-title-bar">
        <div className="title-sec">
          <h2>Positions</h2>
        </div>
        <div className="btn-sec">
          <button className="btn-style" type="submit" onClick={handleOpen}>
            + Add position
          </button>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="table-scroll-mobile payroll-table preferences">
          <SettingsPreferencesTable
            positionsList={positionsList}
            setIsDeleted={setIsUpdateNeed}
            handleEdit={handleEdit}
          />
        </div>
      )}

      <AddOrEditPositionModal
        open={open || !!editPosition}
        handleClose={editPosition ? handleEditClose : handleClose}
        setIsAddComplete={setIsUpdateNeed}
        editPosition={editPosition}
      />
    </div>
  );
};

export default SettingsPreferences;
