import "../assets/css/run-payroll.css";

import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Menu, MenuItem, Tooltip, useMediaQuery } from "@mui/material";
import { Downloading } from "@mui/icons-material";
import RunPayrollPreviewTable from "../components/run-payroll-preview/RunPayrollPreviewTable";
import axios from "../axios";
import { actions as toastActions } from "../store/toast/toast.reducers";
import { actions as dataActions } from "../store/data/data.reducers";
import { actions } from "../store/payroll-preview/payroll-preview.reducers";
import { actions as authActions } from "../store/auth/auth.reducers";
import { calculateProfit, formatMoney, formatNumber } from "../utils";
import { getBreadCrumbs } from "../store/bread-crumbs/bread-crumbs.selectors";
import { actions as breadCrumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers";
import { getPayrollData } from "../store/payroll-preview/payroll-preview.selectors";
import CSV from "../assets/images/run-payroll-preview/csv.svg";
import PDF from "../assets/images/run-payroll-preview/pdf.svg";
import Wex from "../assets/images/run-payroll-preview/wex.svg";
import Rcpa from "../assets/images/run-payroll-preview/rcpa.svg";
import Sunoco from "../assets/images/run-payroll-preview/sunoco.png";
import Coast from "../assets/images/run-payroll-preview/coast-logo.svg";
import PackageRoute from "../assets/images/run-payroll-preview/package-route.png";
import GroundCloud from "../assets/images/run-payroll-preview/GC.svg";
import BeansRoute from "../assets/images/run-payroll-preview/beans-route.png";
import { ReactComponent as TimeCardUpload } from "../assets/images/run-payroll-preview/time-card.svg";
import { ReactComponent as FuelUpload } from "../assets/images/run-payroll-preview/fuel.svg";
import { ReactComponent as Printer } from "../assets/images/run-payroll-preview/printer.svg";
import { ReactComponent as Calculator } from "../assets/images/run-payroll-preview/calculator.svg";
import { ReactComponent as DocumentUpload } from "../assets/images/run-payroll-preview/document-upload.svg";
import PayrollTimeUpload from "../components/modals/PayrollTimeUpload";
import { getUser } from "../store/auth/auth.selectors";
import NotSelectedCompanyModal from "../components/modals/NotSelectedCompanyModal";
import Loading from "../components/common/Loading";
import {
  initiateFileDownloadFromBlob,
  isUserSourceRestricted
} from '../helpers/helpers';
import { getPayrollList } from "../store/payroll/payroll.selectors";
import { actions as payrollReducers } from "../store/payroll/payroll.reducers";
import ConfirmRecalculateModal from "./../components/modals/ConfirmRecalculateModal";
import { getIsProcessPayrollStarted } from "../store/data/data.selectors";
import { useWSPaychex } from "../hooks/useWebSocket";

const RunPayrollPreview = ({ echo }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const userData = useSelector(getUser);
  const isUserRestricted = isUserSourceRestricted();

  const { handlePaychexProcessedListen } = useWSPaychex(userData, echo);

  const breadCrumbs = useSelector(getBreadCrumbs);
  const payrollData = useSelector(getPayrollData);
  const isProcessPayrollStarted = useSelector(getIsProcessPayrollStarted);

  const [isUploadingFuelFile, setIsUploadingFuelFile] = useState(false);
  const [isUploadingTimeFile, setIsUploadingTimeFile] = useState(false);
  const [isUploadingDSWFile, setIsUploadingDSWFile] = useState(false);
  const [isLoadingProcessPayroll, setIsLoadingProcessPayroll] = useState(false);
  const [isProcessPayrollModalOpen, setIsProcessPayrollModalOpen] = useState(false);
  const [isUploadingTimeOpen, setIsUploadingTimeOpen] = useState(false);
  const [uploadingTimeOpenType, setUploadingTimeOpenType] = useState();
  const [isPrintPDFInProgress, setIsPrintPDFInProgress] = useState(false);
  const [isPrintCSVInProgress, setIsPrintCSVInProgress] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElFuel, setAnchorElFuel] = useState(null);
  const [anchorElTimeCard, setAnchorElTimeCard] = useState(null);
  const [isNotSelectedCompanyOpen, setIsNotSelectedCompanyOpen] = useState(false);
  const [isShowProcessPayroll, setIsShowProcessPayroll] = useState(false);

  const payrolls = useSelector(getPayrollList);

  const isMobile = useMediaQuery("(max-width:990px)");

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleFuelOpen = (event) => setAnchorElFuel(event.currentTarget);
  const handleTimeCardOpen = (event) => {
    if(isUserRestricted){
      // in case we don't need to show all time cards
      // like for Package Route Integration
      if(filteredStatementList.length > 1){
        handleUploadTimeModal("package-route")
      }

      return;
    }

    setAnchorElTimeCard(event.currentTarget);
  }

  const handleClose = () => setAnchorEl(null);
  const handleFuelClose = () => setAnchorElFuel(null);
  const handleTimeCardClose = () => setAnchorElTimeCard(null);

  const open = Boolean(anchorEl);
  const openFuel = Boolean(anchorElFuel);
  const openTimeCard = Boolean(anchorElTimeCard);

  const handleDownloadClick = (e) => {
    e.preventDefault();

    if (userData.is_payroll_company_connected) {
      axios({
        method: "get",
        url: `/payroll/${id}/download-wage-calculator`,
        responseType: "blob"
      })
        .then((response) => {
          const currentDate = new Date();

          const formattedDate =
            `${currentDate.getMonth() + 1}`.padStart(2, "0") +
            `${currentDate.getDate()}`.padStart(2, "0") +
            currentDate.getFullYear();

          initiateFileDownloadFromBlob(response, `EmployeePreview-${formattedDate}`);
        })
        .catch((err) =>
          dispatch(
            toastActions.toast({
              code: "error",
              message: err.response?.data?.message || "Something went wrong"
            })
          )
        );
    } else {
      setIsNotSelectedCompanyOpen(true);
    }
  };

  const handleProcessPayrollCloseClick = () => setIsProcessPayrollModalOpen(false);
  const handleProcessPayrollOpenClick = () => setIsProcessPayrollModalOpen(true);

  const checkProcessPayrollStatus = () =>
    axios.get(`/integrations/paychex/check-process-payroll/${id}`).then((res) => {
      dispatch(dataActions.setIsProcessPayrollStarted(res.data.progress || false));
    });

  const handleProcessPayrollClick = (e) => {
    e.preventDefault();

    setIsLoadingProcessPayroll(true);

    axios
      .post(`/integrations/paychex/process-payroll/${id}`)
      .then(() => {
        handleProcessPayrollCloseClick();
        handlePaychexProcessedListen();
        dispatch(dataActions.setIsProcessPayrollStarted(true));
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({
            code: "error",
            message: err.response?.data?.message || "Something went wrong"
          })
        )
      )
      .finally(() => setIsLoadingProcessPayroll(false));
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (payrollData.date && (breadCrumbs.length === 0 || breadCrumbs.length > 1)) {
      dispatch(
        breadCrumbsActions.setBreadCrumbs([
          {
            name: "Wage Calculator",
            url: `/run-payroll`
          },
          {
            name: `${dayjs(payrollData.date).format("MM/DD/YYYY")} - ${dayjs(payrollData.date)
              .add(6, "d")
              .format("MM/DD/YYYY")}`,
            url: `/run-payroll-preview/${id}`
          }
        ])
      );
    } else if (payrollData.date && breadCrumbs.length === 1) {
      dispatch(
        breadCrumbsActions.setBreadCrumbs([
          ...breadCrumbs,
          {
            name: `${dayjs(payrollData.date).format("MM/DD/YYYY")} - ${dayjs(payrollData.date)
              .add(6, "d")
              .format("MM/DD/YYYY")}`,
            url: `/run-payroll-preview/${id}`
          }
        ])
      );
    }
  }, [payrollData]);

  useEffect(() => {
    fetchPayrollData();

    return () => {
      dispatch(actions.resetPayroll());
    };
  }, []);

  const handleUploadFuelClick = (type, e) => {
    e.preventDefault();
    setIsUploadingFuelFile(true);

    const formData = new FormData();
    formData.append("file", e.currentTarget.files[0]);
    formData.append("type", type);

    axios
      .post(`/payroll/${id}/upload-fuel`, formData)
      .then((response) => {
        // setIsFuelUploadDisabled(false);
        fetchPayrollData();
        handleFuelClose();
        response.success &&
          dispatch(toastActions.toast({ code: "success", message: response.message }));
      })
      .catch((error) => {
        const message = error?.response?.data?.message || "Something went wrong";
        dispatch(toastActions.toast({ code: "error", message }));
      })
      .finally(() => setIsUploadingFuelFile(false));
  };

  const fetchPayrollData = () => {
    setLoading(true);

    axios
      .get(`/payroll/${id}`)
      .then((response) => {
        const payrollData = response.data;

        const profit = calculateProfit(+payrollData.total_w2, payrollData);

        const payrollTotalStats = [
          {
            name: "Employees",
            count: payrollData.total_employees
          },
          {
            name: "Stops",
            count: formatNumber(payrollData.total_stops)
          },
          {
            name: "Fuel",
            count: formatMoney(+payrollData.total_fuel === 0 ? 0 : payrollData.total_fuel),
            percentage: ((payrollData.total_fuel / payrollData.total_charges) * 100).toFixed(2) || 0
          },
          {
            name: "Expenses",
            count: formatMoney(+payrollData.total_expenses === 0 ? 0 : payrollData.total_expenses),
            percentage:
              ((payrollData.total_expenses / payrollData.total_charges) * 100).toFixed(2) || 0
          },
          {
            name: "W-2",
            count: formatMoney(payrollData.total_w2),
            percentage: ((payrollData.total_w2 / payrollData.total_charges) * 100).toFixed(2) || 0
          },
          // TODO total_charges - revenue need to check
          {
            name: "Revenue",
            count: formatMoney(payrollData.total_charges)
          },
          // TODO total_per_stop need to check
          {
            name: "Per Stop",
            count: formatMoney(payrollData.total_charges / payrollData.total_stops, true)
          },
          {
            name: "Profit",
            count: formatMoney(profit),
            percentage: ((profit / payrollData.total_charges) * 100).toFixed(2) || 0
          }
        ];

        // payrollData.total_fuel > 0 && setIsFuelUploadDisabled(true);
        batch(() => {
          dispatch(actions.setPayrollTotalStats(payrollTotalStats));
          dispatch(
            actions.setPayrollStatements(
              payrollData.statements.map((el, index) => ({ ...el, order: `${index + 1}` }))
            )
          );
          dispatch(actions.setPayrollData(payrollData));
        });
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => {
        setLoading(false);

        axios
          .get("/integrations/paychex/is-show-process-payroll")
          .then((response) => {
            if (response.data) {
              axios
                .get("/payroll")
                .then((response) =>
                  dispatch(payrollReducers.setPayrollList(response.data.payrolls))
                )
                .catch((err) =>
                  dispatch(
                    toastActions.toast({
                      code: "error",
                      message: err.response?.data?.message || err.message
                    })
                  )
                )
                .finally(() => {
                  setIsShowProcessPayroll(true);
                });
            }
          })
          .catch((err) =>
            dispatch(
              toastActions.toast({
                code: "error",
                message: err.response?.data?.message || err.message
              })
            )
          );
      });
  };

  useEffect(() => {
    if (payrolls[0]?.id == id && isShowProcessPayroll) {
      checkProcessPayrollStatus();
    }
  }, [isShowProcessPayroll]);

  const fetchUserData = () =>
    axios
      .get("/auth/user")
      .then((response) => {
        dispatch(authActions.loginUser(response));
        localStorage.setItem("user", JSON.stringify(response || {}));
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({
            code: "error",
            message: err.response?.data?.message || err.message
          })
        )
      );

  const handlePrintPDFReport = (e) => {
    setIsPrintPDFInProgress(true);

    e.preventDefault();
    axios({
      method: "get",
      url: `/payroll/${id}/download-pdf-report`,
      responseType: "blob"
    })
      .then((response) => {
        initiateFileDownloadFromBlob(response, "report");
      })
      .catch(() => dispatch(toastActions.toast({ code: "error", message: "Something went wrong" })))
      .finally(() => {
        setIsPrintPDFInProgress(false);
        handleClose();
      });
  };

  const handlePrintCSVReport = (e) => {
    setIsPrintCSVInProgress(true);
    handleClose();

    e.preventDefault();
    axios({
      method: "get",
      url: `/payroll/${id}/download-csv-report`,
      responseType: "blob"
    })
      .then((response) => {
        initiateFileDownloadFromBlob(response, "report");
      })
      .catch(() => dispatch(toastActions.toast({ code: "error", message: "Something went wrong" })))
      .finally(() => {
        setIsPrintCSVInProgress(false);
        handleClose();
      });
  };

  const handleUploadDSWClick = (e) => {
    e.preventDefault();
    setIsUploadingDSWFile(true);

    const formData = new FormData();

    const files = e.currentTarget.files;

    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
    }

    axios
      .post(`/payroll/${id}/upload-dsw`, formData)
      .then((response) => {
        fetchPayrollData();
        fetchUserData();
        response.success &&
          dispatch(toastActions.toast({ code: "success", message: response.message }));
      })
      .catch((error) => {
        const message = error?.response?.data?.message || "Something went wrong";
        dispatch(toastActions.toast({ code: "error", message }));
      })
      .finally(() => setIsUploadingDSWFile(false));
  };

  const handleUploadTimeClick = (type, e) => {
    e.preventDefault();
    setIsUploadingTimeFile(true);

    const formData = new FormData();
    formData.append(`${payrollData.statements[0].company_id}`, e.currentTarget.files[0]);
    formData.append("type", type);

    axios
      .post(`/payroll/${id}/upload-time-card`, formData)
      .then((response) => {
        fetchPayrollData();
        fetchUserData();
        handleTimeCardClose();
        response.success &&
          dispatch(toastActions.toast({ code: "success", message: response.message }));
      })
      .catch((error) => {
        const message = error?.response?.data?.message || "Something went wrong";
        dispatch(toastActions.toast({ code: "error", message }));
      })
      .finally(() => setIsUploadingTimeFile(false));
  };

  const filteredStatementList =
    payrollData.statements?.reduce((acc, el) => {
      const isExist = acc.find((elem) => elem.company_id === el.company_id);

      if (isExist) {
        return acc;
      }

      return [...acc, el];
    }, []) || [];

  const handleUploadTimeModal = (type) => {
    if (filteredStatementList.length > 1) {
      setIsUploadingTimeOpen(true);
      setUploadingTimeOpenType(type);
      handleTimeCardClose();
    }
  };

  return (
    <>
      <div className="payroll-header">
        <button
          className="btn-style upload-fuel-btn employees-profile-img-upload dsw"
          disabled={isUploadingDSWFile}>
          {isUploadingDSWFile ? (
            <Loading />
          ) : (
            <>
              <DocumentUpload />
              {!isMobile && "Weekly Service Worksheet"}
              <input
                type="file"
                id="file-uploader"
                accept=".xlsx, .xls"
                onChange={handleUploadDSWClick}
                multiple
              />
            </>
          )}
        </button>
        <button
          className="btn-style upload-fuel-btn employees-profile-img-upload dsw"
          disabled={isUploadingTimeFile}
          onClick={handleTimeCardOpen}>
          <TimeCardUpload />
          {!isMobile && "Time Card"}
          {filteredStatementList.length < 2 && isUserRestricted && (
            <input
              type="file"
              id="file-uploader"
              accept=".csv, .xlsx, .xls"
              onChange={handleUploadTimeClick.bind(null, "package-route")}
            />
          )}
        </button>
        <button
          className="btn-style upload-fuel-btn employees-profile-img-upload dsw"
          disabled={isUploadingFuelFile}
          onClick={handleFuelOpen}>
          {isUploadingFuelFile ? (
            <Loading />
          ) : (
            <>
              <FuelUpload />
              {!isMobile && "Fuel"}
            </>
          )}
        </button>
        <button onClick={handleOpen} className="btn-style upload-fuel-btn dsw">
          <Printer />
          {!isMobile && "Print"}
        </button>
        <button onClick={handleDownloadClick} className="btn-style download-payroll-btn dsw">
          <Calculator />
          {!isMobile && "Export Payroll"}
        </button>
        <Tooltip
          title={
            !(isShowProcessPayroll && payrolls[0]?.id == id) && !isProcessPayrollStarted
              ? "You need connected direct integration to process payroll automatically"
              : ""
          }>
          <button
            onClick={handleProcessPayrollOpenClick}
            className="btn-style download-payroll-btn process-payroll-btn"
            style={{ marginLeft: "16px" }}
            disabled={!(isShowProcessPayroll && payrolls[0]?.id == id) || isProcessPayrollStarted}>
            {isProcessPayrollStarted ? <Loading /> : isMobile ? <Downloading /> : "Process Payroll"}
          </button>
        </Tooltip>
      </div>

      <Menu
        anchorEl={anchorElTimeCard}
        open={openTimeCard}
        onClose={handleTimeCardClose}
        className="more-menu time-card">
        <MenuItem
          className="fuel-upload-menu-item"
          onClick={handleUploadTimeModal.bind(null, "ground-cloud")}>
          <button
            className="document-more-btn menu-item time-card-icon"
            disabled={isPrintPDFInProgress}>
            <img src={GroundCloud} width={32} alt="" />
          </button>
          <h6>GroundCloud</h6>
          {filteredStatementList.length < 2 && (
            <input
              type="file"
              id="file-uploader"
              accept=".csv, .xlsx, .xls"
              onChange={handleUploadTimeClick.bind(null, "ground-cloud")}
            />
          )}
        </MenuItem>
        <MenuItem
          className="fuel-upload-menu-item"
          onClick={handleUploadTimeModal.bind(null, "beans-route")}>
          <button
            className="document-more-btn menu-item time-card-icon"
            disabled={isPrintPDFInProgress}>
            <img src={BeansRoute} alt="" />
          </button>
          <h6>Beans Route</h6>
          {filteredStatementList.length < 2 && (
            <input
              type="file"
              id="file-uploader"
              accept=".csv, .xlsx, .xls"
              onChange={handleUploadTimeClick.bind(null, "beans-route")}
            />
          )}
        </MenuItem>
        <MenuItem
          className="fuel-upload-menu-item"
          onClick={handleUploadTimeModal.bind(null, "package-route")}>
          <button
            className="document-more-btn menu-item time-card-icon"
            disabled={isPrintPDFInProgress}>
            <img src={PackageRoute} alt="" />
          </button>
          <h6>Package Route</h6>
          {filteredStatementList.length < 2 && (
            <input
              type="file"
              id="file-uploader"
              accept=".csv, .xlsx, .xls"
              onChange={handleUploadTimeClick.bind(null, "package-route")}
            />
          )}
        </MenuItem>
      </Menu>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className="more-menu report">
        <MenuItem onClick={handlePrintPDFReport}>
          <button className="document-more-btn menu-item" disabled={isPrintPDFInProgress}>
            <img src={PDF} alt="" />
          </button>
          PDF
        </MenuItem>
        <MenuItem onClick={handlePrintCSVReport}>
          <button className="document-more-btn menu-item" disabled={isPrintCSVInProgress}>
            <img src={CSV} alt="" />
          </button>
          CSV
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorElFuel}
        open={openFuel}
        onClose={handleFuelClose}
        className="more-menu report fuel-modal">
        <MenuItem className="fuel-upload-menu-item fuel">
          <button className="document-more-btn menu-item" disabled={isPrintPDFInProgress}>
            <img src={Wex} alt="" />
          </button>
          Wex
          <input
            type="file"
            id="file-uploader"
            accept=".csv, .xlsx, .xls"
            disabled={isUploadingFuelFile}
            onChange={handleUploadFuelClick.bind(null, "wex")}
          />
        </MenuItem>
        <MenuItem className="fuel-upload-menu-item fuel">
          <button className="document-more-btn menu-item" disabled={isPrintCSVInProgress}>
            <img src={Rcpa} alt="" />
          </button>
          RCPA
          <input
            type="file"
            id="file-uploader"
            accept=".csv, .xlsx, .xls"
            disabled={isUploadingFuelFile}
            onChange={handleUploadFuelClick.bind(null, "rcpa")}
          />
        </MenuItem>
        <MenuItem className="fuel-upload-menu-item fuel">
          <button className="document-more-btn menu-item" disabled={isPrintCSVInProgress}>
            <img src={Sunoco} alt="" />
          </button>
          Sunoco
          <input
            type="file"
            id="file-uploader"
            accept=".csv, .xlsx, .xls"
            disabled={isUploadingFuelFile}
            onChange={handleUploadFuelClick.bind(null, "sunoco")}
          />
        </MenuItem>
        <MenuItem className="fuel-upload-menu-item">
          <button className="document-more-btn menu-item" disabled={isPrintCSVInProgress}>
            <img src={Coast} alt="" style={{width: '30px', height: '17px'}}/>
          </button>
          Coast
          <input
              type="file"
              id="file-uploader"
            accept=".csv, .xlsx, .xls"
            disabled={isUploadingFuelFile}
            onChange={handleUploadFuelClick.bind(null, "coast")}
          />
        </MenuItem>
      </Menu>

      <RunPayrollPreviewTable loading={loading} />

      <PayrollTimeUpload
        open={isUploadingTimeOpen}
        handleClose={() => {
          setIsUploadingTimeOpen(false);
          setUploadingTimeOpenType();
        }}
        fetchPayrollData={fetchPayrollData}
        fetchUserData={fetchUserData}
        payrollId={id}
        statementList={filteredStatementList}
        uploadingTimeOpenType={uploadingTimeOpenType}
      />
      <NotSelectedCompanyModal
        open={isNotSelectedCompanyOpen}
        handleClose={() => setIsNotSelectedCompanyOpen(false)}
      />
      <ConfirmRecalculateModal
        open={isProcessPayrollModalOpen}
        handleClose={handleProcessPayrollCloseClick}
        handleConfirm={handleProcessPayrollClick}
        subText="This process may take some time."
        text="Are you sure you want to Process Payroll?"
        isLoading={isLoadingProcessPayroll}
        confirmButtonText="Confirm"
      />
    </>
  );
};

export default RunPayrollPreview;
