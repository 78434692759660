import OrganizationsContainer from "../../components/organizations/OrganizationsContainer";
import TitleInfo from "../../components/common/TitleInfo";

const SuperAdminOrganizations = () => {
  return (
    <>
      <TitleInfo title="Organizations" subTitle="All the Organizations" />

      <OrganizationsContainer />
    </>
  );
};

export default SuperAdminOrganizations;
