import React from "react";
import { Avatar, Box, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as FaSolidRoute } from "../../assets/images/run-payroll-preview/fa-solid_route.svg";
import AvatarIcon from "../../assets/images/Avatar.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ReactComponent as Danger } from "../../assets/images/run-payroll-preview/danger.svg";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#1D1858",
    boxShadow: "0px 0px 24px 0px #0000001A",
    fontSize: 12,
    borderRadius: "3px",
    fontWeight: 400,
    [`& .MuiTooltip-arrow`]: {
      color: theme.palette.common.white
    }
  }
}));

const StyledBadge = styled(Box)(({ theme }) => ({
  width: 10,
  height: 10,
  border: `1px solid white`,
  borderRadius: "50%",
  marginTop: "6px",
  marginRight: "6px"
}));

const CustomAvatar = ({
  variant,
  className,
  alt,
  src,
  withBadge = false,
  badgeType = "",
  isMultipleIcon,
  warnings = [],
}) => {
  const isWeekly = badgeType?.toLowerCase() === "weekly";

  let warningMessages = warnings.length
    ? warnings.map(warning => warning.warning_message)
    : null;

  return (
    <Badge
      sx={{ height: "36px", position: "relative" }}
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        withBadge ? (
          <Tooltip title={isWeekly ? "Weekly" : "Daily"} arrow>
            <StyledBadge
              style={
                isWeekly ? { backgroundColor: "#4253F0" } : { backgroundColor: "#16AB6D" }
              }></StyledBadge>
          </Tooltip>
        ) : (
          ""
        )
      }>
      <Avatar variant={variant} className={className} alt={alt} src={src || AvatarIcon} />
      {warningMessages && (
        <div className="warning-alert">
          <LightTooltip title={warningMessages.join('. ')} arrow>
            <Danger />
          </LightTooltip>
        </div>
      )}
      {isMultipleIcon && (
        <Tooltip title="Multiple Station" arrow>
          <div className="multiple-icon-station">
            <FaSolidRoute />
          </div>
        </Tooltip>
      )}
    </Badge>
  );
};

export default CustomAvatar;
