import TableSortArrowsBlock from "../common/TableSortArrowsBlock";
import ExpensesTableRow from "./ExpensesTableRow";

const ExpensesTable = ({ payrolls, handleSortChange, activeSortedItem }) => {
  return (
    <div className="payroll-table expenses">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              <div className="leaderboard-top-table-header-item">
                Date Uploaded{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  itemName="created_at"
                  activeSortedItem={activeSortedItem}
                />
              </div>
            </th>
            <th>
              <div className="leaderboard-top-table-header-item">
                Last Updates{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  itemName="updated_at"
                  activeSortedItem={activeSortedItem}
                />
              </div>
            </th>
            <th>
              <div className="leaderboard-top-table-header-item justify-content-end">
                Expenses{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  itemName="total_expenses"
                  activeSortedItem={activeSortedItem}
                />
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {payrolls.map((el, index) => (
            <ExpensesTableRow key={el.id} itemData={el} index={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExpensesTable;
