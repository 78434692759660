import { useDispatch } from "react-redux";
import { useState } from "react";
import "../assets/css/leaderboard.css";
import TitleInfo from "../components/common/TitleInfo";
import TopEmployees from "../components/leaderboard/TopEmployees";
import axios from "../axios";
import { actions as toastActions } from "../store/toast/toast.reducers";
import { ReactComponent as Refresh } from "../assets/images/refresh-circle.svg";

const Leaderboard = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [filterBy, setFilterBy] = useState(1);

  const handleRecalculate = () => {
    setLoading(true);

    axios
      .post("/leaderboard/recalculate", { filter: filterBy })
      .then((response) => {
        if (response.success) {
          setIsUpdated(true);
          dispatch(toastActions.toast({ code: "success", message: "Updated success" }));
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <TitleInfo
        title="Leaderboard"
        subTitle="Leading the pack: The top-ranked workers"
        buttons={[
          {
            name: "Update",
            class: "btn-style add-employee-btn leaderboard-btn",
            onClick: handleRecalculate,
            loading: loading,
            icon: <Refresh className="leaderboard-btn-icon" />
          }
        ]}
      />

      <TopEmployees setFilter={setFilterBy} isUpdated={isUpdated} setIsUpdated={setIsUpdated} />
    </>
  );
};

export default Leaderboard;
