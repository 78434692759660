import { West } from "@mui/icons-material";
import { Box, Button, Container, SvgIcon, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import Error from "../assets/images/errors/error-404.png";

const Error404Page = () => {
  const nav = useNavigate();

  const handleLogoClick = () => nav("/");

  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%"
        }}>
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column"
            }}>
            <Box
              sx={{
                mb: 3,
                textAlign: "center"
              }}>
              <img
                alt="Under development"
                src={Error}
                style={{
                  display: "inline-block",
                  maxWidth: "100%",
                  width: 400
                }}
              />
            </Box>
            <Typography align="center" sx={{ mb: 3 }} variant="h3">
              404: The page you are looking for isn’t here
            </Typography>
            <Typography align="center" color="text.secondary" variant="body1">
              You either tried some shady route or you came here by mistake. Whichever it is, try
              using the navigation
            </Typography>
            <Button
              onClick={handleLogoClick}
              startIcon={
                <SvgIcon fontSize="small">
                  <West />
                </SvgIcon>
              }
              sx={{ mt: 3 }}
              variant="contained">
              Go to overview page
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Error404Page;
