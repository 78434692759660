import "../../assets/css/modal.css";
import { Modal } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import Loading from "./../common/Loading";

const ConfirmRecalculateModal = ({
  open,
  handleClose,
  handleConfirm,
  text,
  subText,
  isLoading,
  confirmButtonText,
  icon
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper">
          <div className="confirm-modal-close-wrapper" onClick={handleClose}>
            <CloseIcon />
          </div>

          <div className="confirm-modal-icon-wrapper recalculate">
            {icon || <InfoOutlined className="confirm-modal-icon" />}
          </div>

          {text}
          {subText && <div>{subText}</div>}

          <div className="confirm-modal-btn-wrapper">
            <button className="confirm-modal-btn cancel" onClick={handleClose}>
              Cancel
            </button>
            <button
              className="confirm-modal-btn recalculate"
              onClick={handleConfirm}
              disabled={isLoading}>
              {isLoading ? <Loading /> : confirmButtonText || "Recalculate"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmRecalculateModal;
