import "../../assets/css/modal.css";
import { FormHelperText, Modal, TextField } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NumericFormat } from "react-number-format";
import { useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import Loading from "../common/Loading";
import { ReactComponent as AddCircle } from "../../assets/images/settings/add-circle.svg";
import { ReactComponent as Edit } from "../../assets/images/settings/edit.svg";
import { getIsDemoMode } from "./../../store/auth/auth.selectors";
import { useSelector } from "react-redux";
import {isUserSourceRestricted} from "../../helpers/helpers";

const styles = {
  error: {
    "& div input": {
      borderColor: "red",
      borderLeft: "1px solid red !important"
    }
  }
};

const schema = yup.object().shape({
  name: yup.string().required("Name Station is required!"),
  csa: yup.string().required("CSA is required!")
});

const StationModal = ({ open, handleClose, editedStation, handleConfirm, isLoading }) => {
  const isDemoMode = useSelector(getIsDemoMode);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      name: "",
      csa: "",
      minimum_wage: 7.25
    },
    resolver: yupResolver(schema)
  });

  const isUserRestricted = isUserSourceRestricted();

  const { minimum_wage } = watch();

  useEffect(() => {
    if (editedStation) {
      setValue("name", editedStation.name, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("csa", editedStation.csa, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [editedStation]);

  const handleCurClose = () => {
    handleClose();
    reset();
  };

  const onSubmit = (values) => {
    if (values.minimum_wage >= 7.25) {
      handleConfirm(values, handleCurClose);
    } else {
      setError("minimum_wage", { type: "custom", message: "Minimum Wage 7.25$" });
    }
  };

  const handleWageChange = (v) =>
    setValue("minimum_wage", v, {
      shouldValidate: true,
      shouldDirty: true
    });

  useEffect(() => {
    if (open && editedStation) {
      setValue("minimum_wage", editedStation.minimum_wage, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleCurClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper">
          <div className="confirm-modal-close-wrapper" onClick={handleCurClose}>
            <CloseIcon />
          </div>

          <div className="confirm-modal-icon-wrapper recalculate">
            {editedStation ? (
              <Edit className="confirm-modal-icon company" />
            ) : (
              <AddCircle className="confirm-modal-icon company" />
            )}
          </div>

          <div>{editedStation ? "Edit" : "Add"} Station</div>

          <form className="onboard-form company-add-modal">
            <div className="form-item">
              <label className={errors.name?.message && "error"}> Name Station *</label>
              <div className="input-box">
                <TextField
                  sx={errors.name?.message && styles.error}
                  fullWidth
                  type="text"
                  placeholder=" "
                  name="name"
                  disabled={isUserRestricted}
                  required
                  {...register("name")}
                  error={touchedFields.name && !!errors.name?.message}
                  helperText={errors.name?.message}
                />
              </div>
            </div>

            <div className="form-item">
              <label className={errors.csa?.message && "error"}>Contracted Service Area *</label>
              <div className="input-box">
                <TextField
                  sx={[errors.csa?.message && styles.error]}
                  fullWidth
                  required
                  type="number"
                  disabled={isUserRestricted}
                  name="csa"
                  className={isDemoMode && editedStation ? "blur-text" : ""}
                  {...register("csa")}
                  error={touchedFields.csa && !!errors.csa?.message}
                  helperText={errors.csa?.message}
                />
              </div>
            </div>
            <div className="form-item">
              <label className={errors.minimum_wage?.message && "error"}>
                State Minimum Wage *
              </label>
              <div className="input-box">
                <NumericFormat
                  onValueChange={(values) => handleWageChange(values.floatValue)}
                  className={`station-modal-input ${errors.minimum_wage?.message ? "error" : ""}`}
                  value={minimum_wage}
                  decimalScale={2}
                  fixedDecimalScale
                  onFocus={(event) => event.target.select()}
                  thousandSeparator
                  prefix="$"
                  allowNegative={false}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue < 999 || [0, null, undefined].includes(floatValue);
                  }}
                />
                {!!errors.minimum_wage?.message && (
                  <FormHelperText component="div" error={!!errors.minimum_wage?.message}>
                    {errors.minimum_wage?.message}
                  </FormHelperText>
                )}
              </div>
            </div>
          </form>

          <div className="confirm-modal-btn-wrapper">
            <button className="confirm-modal-btn cancel company-btn" onClick={handleCurClose}>
              Cancel
            </button>
            <button
              className="confirm-modal-btn company company-btn"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}>
              {isLoading ? <Loading /> : editedStation ? "Save changes" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StationModal;
