const styles = {
    label: {
        fontFamily: 'Gilroy',
        fontSize: '14px',
        fontWeight: 500,
        color: '#8D98A9',
        mb: 0.5,
        '&.Mui-focused, &.MuiFormLabel-root.Mui-error': {
            color: '#8D98A9',
        },
    },
    selector: {
        '& .MuiFormControl-root > div.MuiInputBase-root.MuiOutlinedInput-root': {
            py: 1,
            pl: 1.5,
            pr: 4.5,
        },
        '& .MuiAutocomplete-input.MuiOutlinedInput-input.MuiInputBase-input': {
            minHeight: 24,
            p: 0,
            borderRadius: 0,
            '&::placeholder': {
                opacity: 1,
                color: '#717E94',
                fontFamily: 'Gilroy',
                fontSize: '14px',
                fontWeight: 500,
            },
        },
        '& .MuiInputBase-root': {
            border: '1px solid',
            borderColor: '#EEF0F2',
            gap: 1,
            fontFamily: 'Gilroy',
            fontSize: '14px',
            fontWeight: 500,
            '&.Mui-focused': {
                borderColor: '#7B87F5',
                boxShadow: '0px 0px 0px 4px #408BFA26'
            },
            '& fieldset': {
                border: 'none',
            },
        },
    },
    chip: {
        '&.MuiChip-root.MuiButtonBase-root': {
            fontSize: '14px',
            m: 0,
            height: 24,
            backgroundColor: 'colors.statusNeutralLight',
            borderRadius: '3px',
            '& .MuiSvgIcon-root': {
                width: 14,
                height: 14,
                color: '#8D98A9',
            },
        },
    },
    paper: {
        my: '8px',
        py: '8px',
        border: '1px solid #EAEAEA',
        boxShadow: 'none',
        '&.MuiPaper-rounded': {
            borderRadius: '8px',
        },
        '& .MuiAutocomplete-listbox': {
            py: '8px',
        },
        '& .MuiAutocomplete-option': {
            minHeight: 40,
        },
        '& .MuiAutocomplete-noOptions': {
            py: '8px',
        },
        '&.MuiPaper-rounded::-webkit-scrollbar': {
            width: '4px',
            marginLeft: '8px',
        },
        '&.MuiPaper-rounded::-webkit-scrollbar-thumb': {
            backgroundColor: '#EAECF0',
            borderRadius: '8px'
        },
        '&.MuiPaper-rounded::-webkit-scrollbar-thumb:hover': {
            background: '#bdbfc4'
        }
    },
    doneIcon: {
        position: 'absolute',
        right: '8px',
        color: '#8D98A9',
    },
};

export default styles;
