import { useCallback, useMemo } from "react";
import { KeyboardArrowDown, Close } from "@mui/icons-material";
import { Autocomplete, Avatar, FormControl, FormLabel, TextField } from "@mui/material";
import styles from "./styles";

const SelectWithSearch = ({
  options,
  placeholder,
  label,
  id,
  multiple,
  error,
  required,
  disabled,
  sx,
  labelSx,
  setValue,
  value,
  fullWidth,
  user
}) => {
  const handleChange = (_, newValue) => {
    if (Array.isArray(newValue)) {
      setValue(newValue.mapByProperty("value"));
      return;
    }

    setValue(newValue || {});
  };

  const preparedValue = useMemo(() => {
    if (!options.length) {
      return multiple ? [] : null;
    }

    if (multiple && Array.isArray(value)) {
      return options.filter((option) => value.includes(option.value || ""));
    }

    return options.find((option) => option.id === value) || null;
  }, [options, multiple, value]);

  const renderSelectedOptions = useCallback(
    (option) => (user ? `${option.first_name} ${option.last_name}` : option.name),
    []
  );

  const isError = Boolean(error);

  const renderInput = useCallback(
    (params) => <TextField {...params} placeholder={placeholder} error={isError} name={id} />,
    [id, isError, placeholder]
  );

  const renderOption = useCallback((props, option, state) => {
    if (user && !option.first_name && !option.last_name) {
      return null;
    }
    if (!user && !option.name) {
      return null;
    }

    return (
      <li
        {...props}
        key={`${option.id}-${state.index}`}
        className={`select-with-search-li ${state.selected ? "active" : ""}`}>
        {user ? (
          <>
            <Avatar
              variant="circular"
              className="select-with-search-li-avatar"
              alt={option?.first_name}
              src={option?.profile_image}
            />
            <div className="statement-name-block">
              {option.first_name} {option.last_name}
            </div>
          </>
        ) : (
          option.name
        )}
      </li>
    );
  }, []);

  const labelStyles = Array.isArray(labelSx) ? labelSx : [labelSx];

  return (
    <FormControl
      required={required}
      error={isError}
      disabled={disabled}
      sx={sx}
      fullWidth={fullWidth}>
      {label && (
        <FormLabel
          required={required}
          disabled={disabled}
          error={isError}
          sx={[styles.label, ...labelStyles]}>
          {label}
        </FormLabel>
      )}
      <Autocomplete
        id={id}
        options={options}
        value={preparedValue}
        onChange={handleChange}
        disableCloseOnSelect={multiple}
        multiple={multiple}
        blurOnSelect={!multiple}
        selectOnFocus={multiple}
        disabled={disabled}
        noOptionsText={"No results found"}
        getOptionLabel={renderSelectedOptions}
        renderInput={renderInput}
        renderOption={renderOption}
        sx={styles.selector}
        popupIcon={<KeyboardArrowDown />}
        ChipProps={{ sx: styles.chip, deleteIcon: <Close /> }}
        ListboxProps={{ style: { maxHeight: 300 } }}
        componentsProps={{ paper: { sx: styles.paper } }}
        clearIcon={null}
      />
    </FormControl>
  );
};

export default SelectWithSearch;
