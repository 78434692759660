import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './pages/Login';
import AppPage from './pages/AppPage';
import OnboardCompanyInfo from './pages/OnboardCompanyInfo';
import Employees from './pages/Employees';
import Leaderboard from './pages/Leaderboard';
import Overview from './pages/Overview';
import RunPayrollPreview from './pages/RunPayrollPreview';
import RunPayroll from './pages/RunPayroll';
import Settings from './pages/Settings';
import Layout from './components/Layout';
import Reset from './pages/Reset';
import RunPayrollStatement from './pages/RunPayrollStatement';
import { getToken, getUser, isSuperAdmin } from './store/auth/auth.selectors';
import SuperAdminOrganizations from './admin-panel/pages/SuperAdminOrganizations';
import EmailVerify from './pages/EmailVerify';
import PendingRequests from './admin-panel/pages/PendingRequests';
import SocialiteGoogle from './pages/SocialiteGoogle';
import AdminWrapper from './admin-panel/AdminWrapper';
import Error404Page from './pages/Error404Page';
import Expenses from './pages/Expenses';
import ExpensesPreview from './pages/ExpensesPreview';
import SignUp from './pages/SignUp';
import Insights from './admin-panel/pages/Insights';
import Coupons from './admin-panel/pages/Coupons';
import ServiceUnavailable from './pages/ServiceUnavailable';
import { userRoles } from './constants';
import { hotjar } from 'react-hotjar';
import Smartlook from 'smartlook-client';

const routesArray = [
  {
    pathname: 'app',
    component: AppPage
  },
  {
    pathname: 'employees',
    component: Employees
  },
  {
    pathname: 'leaderboard',
    component: Leaderboard
  },
  {
    pathname: '/',
    component: Overview
  },
  {
    pathname: 'run-payroll-preview/:id',
    component: RunPayrollPreview
  },
  {
    pathname: 'run-payroll-preview/:payrollId/:statementId',
    component: RunPayrollStatement
  },
  {
    pathname: 'run-payroll',
    component: RunPayroll
  },
  {
    pathname: 'settings',
    component: Settings
  },
  {
    pathname: 'expenses',
    component: Expenses
  },
  {
    pathname: 'expenses-preview/:payrollId',
    component: ExpensesPreview
  }
];

const ProtectedRoute = ({ auth, redirectPath = '/login', children }) => {
  if (!auth) {
    return <Navigate to={redirectPath} />;
  }

  return children ? children : <Outlet />;
};

export default function Routing() {
  const token = useSelector(getToken);
  const curToken = localStorage.getItem('token') || token;

  const isUserSuperAdminFromLocalStorage = JSON.parse(localStorage.getItem("user") || "{}")?.roles?.filter(el => el.name === userRoles.SUPER_ADMIN).length > 0;
  const isUserSuperAdmin = useSelector(isSuperAdmin);
  const curIsUserSuperAdmin = isUserSuperAdminFromLocalStorage || isUserSuperAdmin;

  const loggedUser = useSelector(getUser);

  if (process.env.REACT_APP_SMARTLOOK_SITE_ID) {
    Smartlook.init(process.env.REACT_APP_SMARTLOOK_SITE_ID);

    Smartlook.record({
      forms: true,
      ips: true,
      numbers: true,
      emails: true,
      api: true
    });

    if (loggedUser && loggedUser.id) {
      Smartlook.identify(loggedUser.id, {
        'user_email': loggedUser.email,
        'organization_id': loggedUser.organization.id,
        'organization_name': loggedUser.organization.name,
        'is_subscribed': loggedUser.is_subscribed
      });
    }
  }

  if (process.env.REACT_APP_HOTJAR_SITE_ID) {
    hotjar.initialize({
      id: process.env.REACT_APP_HOTJAR_SITE_ID,
      sv: 1
    });
  }

  return (
    <Routes>
      <Route path="503" element={<ServiceUnavailable />} />

      <Route element={<ProtectedRoute auth={!curToken} redirectPath="/" />}>
        <Route path="email/verify" element={<EmailVerify />} />
        <Route path="socialite/google/callback" element={<SocialiteGoogle />} />
        <Route path="login" element={<Login />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="reset" element={<Reset />} />
      </Route>

      <Route path="*" element={<Error404Page />} />

      <Route element={<ProtectedRoute auth={curToken && curIsUserSuperAdmin} redirectPath="/" />}>
        <Route path="admin/organizations" element={<AdminWrapper><SuperAdminOrganizations /></AdminWrapper>} />
        <Route path="admin/pending-requests" element={<AdminWrapper><PendingRequests /></AdminWrapper>} />
        <Route path="admin/insights" element={<AdminWrapper><Insights /></AdminWrapper>} />
        <Route path="admin/coupons" element={<AdminWrapper><Coupons /></AdminWrapper>} />
      </Route>

      <Route element={<ProtectedRoute auth={curToken} />}>
        <Route path="onboard-company" element={<OnboardCompanyInfo />} />
        {routesArray.map((el, index) => {
          const Component = el.component;
          return (
            <Route
              key={index}
              path={el.pathname}
              element={
                <Layout
                  pathname={el.pathname}
                  isWageCalculatorPage={['run-payroll-preview/:id', 'run-payroll-preview/:payrollId/:statementId', 'run-payroll'].includes(el.pathname)}
                >
                  <Component />
                </Layout>
              }
            />
          );
        })}

        {/* <Route path="profile" element={<Profile />} />

        <Route path="documents" element={<Documents />}>
          <Route path=":id" element={<Documents />} />
        </Route>

        <Route path="reporting" element={<Reporting />}>
          <Route path=":name" element={<Reporting />} />
        </Route>

        <Route path="patients" element={<Patients />}>
          <Route path="change" element={<Patients />} />
          <Route path="remove" element={<Patients />} />
          <Route path="edit" element={<Patients />} />
        </Route>
        <Route path="patients/list" element={<PatientsList />} />

        <Route path="appraisal" element={<Appraisal />} />

        <Route path="procedure/:id" element={<Procedure />} /> */}
      </Route>
    </Routes>
  );
}
