import FolderIcon from '../../assets/images/run-payroll/folder.png';
import LinearProgress from '../shared/LinearProgress';

const RunPayrollUploadProcess = ({ handleUploadCancel }) => {

  return (
    <div className='file-upload-after-main'>
      <div className='file-upload-after'>
        <div className='file-upload-after-name'>
          <img src={FolderIcon} alt='' />
          <strong id='file-name'>Loading...</strong>
        </div>
        <div className='file-upload-progress'>
          <LinearProgress />
        </div>
        <button className='btn-style file-upload-cancel'
                onClick={handleUploadCancel}>Cancel
        </button>
      </div>
    </div>
  );
};

export default RunPayrollUploadProcess;
