import { Alert, AlertTitle, Snackbar, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../store/toast/toast.reducers";
import { toastSelector } from "../../store/toast/toast.selectors";

export default function Toast() {
  const dispatch = useDispatch();
  const { open, code, message } = useSelector(toastSelector);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(actions.resetToast());
  };

  if (!code) {
    return null;
  }

  return (
    <Stack spacing={2} sx={{ width: "100%", zIndex: "10000000" }}>
      <Snackbar
        open={open}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleClose} severity={code ?? "success"} sx={{ whiteSpace: "pre-line" }}>
          <AlertTitle>{code === "success" ? "Success" : "Error"}</AlertTitle>
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </Alert>
      </Snackbar>
    </Stack>
  );
}
