import './assets/css/style.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import Routing from './Routing';
import store from './store/store';
import Toast from './components/common/Toast';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        {/* <PageAuthDefender> */}
        <div
          style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
          <Routing />
          <Toast />
          {/* <Toast /> */}
        </div>
        {/* </PageAuthDefender> */}
      </BrowserRouter>
    </Provider>
  );
};

export default App;
