import dayjs from "dayjs";
import { formatMoney } from "./../../../utils";
import { ReactComponent as FileTypePdf } from "../../../assets/images/settings/file-type-pdf.svg";

const SubscriptionBillingTableRow = ({ itemData }) => {
  const { created, amount_paid, number, status, invoice_pdf } = itemData;

  const handleDownload = () => window.open(invoice_pdf, "_self");

  return (
    <tr>
      <td>
        <div className="billing-invoice">
          <FileTypePdf />
          <div> Invoice-{number}</div>
        </div>
      </td>
      <td>{dayjs(created * 1000).format("MMM DD, YYYY")}</td>
      <td>{formatMoney(amount_paid > 0 ? amount_paid / 100 : amount_paid, true)}</td>
      <td>{status}</td>
      <td>
        <button className="billing-download" onClick={handleDownload}>
          Download
        </button>
      </td>
    </tr>
  );
};

export default SubscriptionBillingTableRow;
