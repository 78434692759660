// import { useSelector } from "react-redux";
import AccountIcon1Big from "../../assets/images/settings/account-icon-1-big.png";
import AccountIcon2Big from "../../assets/images/settings/account-icon-2-big.png";
import AccountIcon3Big from "../../assets/images/settings/account-icon-3-big.png";
import ExpensesIcon from "../../assets/images/settings/expenses.png";
import RankingBig from "../../assets/images/settings/ranking-big.png";
import WorkspaceIcon2Big from "../../assets/images/settings/workspace-icon-2-big.png";
import { isUserSourceRestricted } from "../../helpers/helpers";
// import SettingsBig from "../../assets/images/settings/settings-big.png";
// import { isSuperAdmin } from "../../store/auth/auth.selectors";

const SettingsLeftMenu = ({ handlePageChange, page }) => {
  const isActive = (pageKey) => (page === pageKey ? "active" : "");

  const isUserRestricted = isUserSourceRestricted();

  const menuItems = {
    account: [
      { key: "profile", label: "My Profile", icon: AccountIcon1Big, restricted: isUserRestricted },
      { key: "subscription", label: "Subscription", icon: RankingBig, restricted: isUserRestricted }
    ],
    organization: [
      { key: "companies", label: "Company", icon: AccountIcon2Big, restricted: false },
      { key: "users", label: "Users", icon: WorkspaceIcon2Big, restricted: isUserRestricted },
      { key: "preferences", label: "Preference", icon: AccountIcon3Big, restricted: false },
      { key: "fixed-expenses", label: "Fixed Expense", icon: ExpensesIcon, restricted: false }
    ]
  };

  // Helper to check if all items in a section are restricted
  const shouldDisplaySection = (items) => items.some(item => !item.restricted);

  return (
      <div className="profile-sidebar">
        {Object.entries(menuItems).map(([section, items]) => (
            // Only display the section if there's at least one item that's not restricted
            shouldDisplaySection(items) && (
                <div className="sidebar-menus" key={section}>
                  {!isUserRestricted && (
                      <h6>{section.charAt(0).toUpperCase() + section.slice(1)}</h6>
                  )}
                  <ul>
                    {items.map(({key, label, icon, restricted }) => (
                        !restricted && (
                            <li key={key}>
                              <button
                                  onClick={handlePageChange.bind(null, key)}
                                  className={isActive(key)}>
                                <img src={icon} alt="" />
                                {label}
                              </button>
                            </li>
                        )
                    ))}
                  </ul>
                </div>
            )
        ))}
      </div>
  );
};

export default SettingsLeftMenu;
