import { ReactComponent as Coin } from "../../assets/images/overview/coin.svg";
import { ReactComponent as MoneySend } from "../../assets/images/overview/money-send.svg";
import { ReactComponent as Location } from "../../assets/images/overview/location.svg";
import { ReactComponent as PercentageCircle } from "../../assets/images/overview/percentage-circle.svg";
import TotalStatisticBlock from './TotalStatisticBlock';
import { formatMoney, formatNumber } from '../../utils';

const TotalStatisticBlocks = ({ totalData, isLoading, displayBy }) => {
  const {
    profit_margin = 0,
    profit_margin_amount = 0,
    total_revenue,
    total_stops,
    last_week_total_revenue_percentage,
    last_week_total_stops_percentage,
    last_week_profit_margin_percentage
  } = totalData;

  return (
    <ul>
      <li>
        <TotalStatisticBlock
          value={formatMoney(total_revenue)}
          percentageSubValue={last_week_total_revenue_percentage}
          isLoading={isLoading}
          displayBy={displayBy}
          text='Total Revenue'
          icon={<MoneySend />}
        />
        <TotalStatisticBlock
          value={formatNumber(total_stops)}
          percentageSubValue={last_week_total_stops_percentage}
          isLoading={isLoading}
          displayBy={displayBy}
          text='Total Stops'
          icon={<Location />}
        />
      </li>

      <li>

        <TotalStatisticBlock
          value={formatMoney(profit_margin_amount)}
          percentageSubValue={last_week_profit_margin_percentage}
          isLoading={isLoading}
          displayBy={displayBy}
          text='Profit Margin'
          icon={<Coin />}
        />

        <TotalStatisticBlock
          value={`${profit_margin}%`}
          percentageSubValue={last_week_profit_margin_percentage}
          isLoading={isLoading}
          displayBy={displayBy}
          text='Profit Margin'
          icon={<PercentageCircle />}
        />
      </li>
    </ul>
  );
};

export default TotalStatisticBlocks;
