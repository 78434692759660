import { Edit, MoreVert } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import DeleteIcon from "../../../assets/images/run-payroll/delete-filled.svg";
import ConfirmDeleteModal from "../../modals/ConfirmDeleteModal";
import axios from "../../../axios";
import { actions as toastActions } from "../../../store/toast/toast.reducers";

const SettingsPreferencesTableRaw = ({ positionData, handleEdit, setIsDeleted }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const open = Boolean(anchorEl);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleOpenDeleteModal = () => {
    handleClose();
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const handleCloseDeleteModal = () => {
    handleClose();
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const handleConfirmDelete = () => {
    setIsDeleteLoading(true);
    axios
      .delete(`settings/positions/${positionData.id}`)
      .then((res) => {
        if (res.success) {
          dispatch(toastActions.toast({ code: "success", message: "Deleted successfully" }));
          handleCloseDeleteModal();
          setIsDeleted(true);
        } else {
          dispatch(toastActions.toast({ code: "error", message: res.data.message }));
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setIsDeleteLoading(false));
  };

  const handleEditPosition = () => {
    handleClose();
    handleEdit(positionData);
  };

  return (
    <tr>
      <td>{positionData.name}</td>
      <td>{dayjs(positionData.created_at).format("MMM DD, YYYY")}</td>
      <td>
        <button className="document-more-btn" style={{ margin: "auto" }} onClick={handleOpen}>
          <MoreVert className="document-more-btn-icon" />
        </button>

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className="more-menu">
          <MenuItem onClick={handleEditPosition}>
            <button className="document-more-btn menu-item">
              <Edit />
            </button>
            Edit
          </MenuItem>
          <MenuItem onClick={handleOpenDeleteModal} disabled={isDeleteLoading}>
            <button className="document-more-btn menu-item">
              <img src={DeleteIcon} alt="" />
            </button>
            Delete
          </MenuItem>
        </Menu>
      </td>

      <ConfirmDeleteModal
        open={isOpenDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleConfirmDelete}
        text="Are you sure delete this Position?"
      />
    </tr>
  );
};

export default SettingsPreferencesTableRaw;
