import EmptyState from "../../common/EmptyState";
import Gusto from "../../../assets/images/settings/gusto.svg";
import Paychex from "../../../assets/images/settings/paychex.svg";
import Adp from "../../../assets/images/settings/adp.svg";
import CorporatePayrollServices from "../../../assets/images/settings/corporate_payroll_services.png";
import Netchex from "../../../assets/images/settings/netchex.png";
import SettingsApplicationsCompanyRow from "./ManualApplicationsCompanyRow";

const icons = {
  Gusto: Gusto,
  Paychex: Paychex,
  "ADP Run": Adp,
  "ADP Run GTSG": Adp,
  "Corporate Payroll Services": CorporatePayrollServices,
  Netchex: Netchex
};

const ManualApplicationsRow = ({ itemData, handleEdit, handleChangeCompany }) => {
  const { name, companies, isActive } = itemData;

  const isGusto = name === "Gusto";
  const isNetchex = name === "Netchex";
  const isCorporatePayrollServices = name === "Corporate Payroll Services";

  const isDefaultCompanyFieldsOnly = isCorporatePayrollServices || isNetchex || isGusto;

  const isCommingSoon = null;
  return (
    <li
      style={{
        border: isActive ? "1px solid #7B87F5" : "1px solid #EEF0F2",
        padding: "24px",
        minHeight: "100px"
      }}>
      <div className="applications-item">
        <div className="applications-item-content">
          <img src={icons[name]} alt="" />
          <div className="applications-item-text">
            <div>{name}</div>
            <div>Connect {name} to generate CSV file</div>
          </div>
        </div>
        <button
          disabled={isCommingSoon || isActive}
          onClick={
            !isCommingSoon && !isActive ? handleChangeCompany.bind(null, itemData) : () => {}
          }
          className={`companies-btn ${
            isCommingSoon ? "coming-soon" : isActive ? "connected" : "connect-now"
          }`}>
          {isCommingSoon ? "Coming soon" : isActive ? "Connected" : "Connect now"}
        </button>
      </div>

      {!isCommingSoon && isActive && !isDefaultCompanyFieldsOnly && (
        <>
          {companies?.length < 1 || !companies ? (
            <div className="companies-informatio-sec">
              <EmptyState title="No added Company" />
            </div>
          ) : (
            <div className="settings-applications-company-row">
              {companies.map((el) => (
                <SettingsApplicationsCompanyRow
                  key={el.id}
                  companyData={el}
                  handleEdit={handleEdit}
                  type={name}
                />
              ))}
            </div>
          )}
        </>
      )}
    </li>
  );
};

export default ManualApplicationsRow;
