const InsightsTotalBlock = ({ itemData }) => {
  const { icon, count, name, active_count, trial_count } = itemData;

  return (
    <li>
      <img src={icon} alt='' />
      <h5>{name}</h5>
      <h5 className="active">{active_count ? <p>Active: {active_count}</p>
        : null} </h5>
      <h5 className="trial">{trial_count
        ? <p>Trial: {trial_count}</p>
        : null} </h5>
      <p>Total: {count}</p>
    </li>
  );
};

export default InsightsTotalBlock;
