import "../../assets/css/modal.css";
import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "../../axios";
import { actions as toastActions } from "../../store/toast/toast.reducers";
import Loading from "../common/Loading";
import CloseCircle from "../../assets/images/close-circle.png";
import SelectWithSearch from "../UI/selectWithSearch/SelectWithSearch";

const AddExistingEmployeeModal = ({ open, handleClose, setIsAddComplete }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const pathnameItems = pathname.split("/");

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      axios
        .get(`/statement/${pathnameItems[pathnameItems.length - 1]}/get-custom-employee-list`)
        .then((response) => {
          if (response.success) {
            setEmployeeList(response.data);
          }
        })
        .catch((err) =>
          dispatch(
            toastActions.toast({
              code: "error",
              message: err.response?.data?.message || err.message
            })
          )
        )
        .finally(() => setLoading(false));
    }
  }, [open]);

  const handleMainClose = () => {
    handleClose();
    setSelectedEmployee();
  };

  const onSubmit = () => {
    setSaveLoading(true);

    axios
      .post(
        `statement/${pathnameItems[pathnameItems.length - 1]}/add-custom-employee/${
          selectedEmployee.id
        }`
      )
      .then((response) => {
        if (response.success) {
          dispatch(toastActions.toast({ code: "success", message: "Employee successfully added" }));
          handleMainClose();
          setIsAddComplete(true);
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setSaveLoading(false));
  };

  const handleEmployeeChange = (newValue) => setSelectedEmployee(newValue);

  return (
    <Modal open={open} onClose={handleMainClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper add-member-modal">
          <div className="add-member-modal-header add-member-modal-header">
            <div className="title-sec">
              <h3>Add Employee</h3>
            </div>

            <div className="confirm-modal-close-wrapper popup-close" onClick={handleMainClose}>
              <img src={CloseCircle} alt="" />
            </div>
          </div>

          <div className="profile-form-sec add-member-modal-form add-existing-employee">
            {loading ? (
              <Loading />
            ) : (
              <div className="form-item add-existing">
                <SelectWithSearch
                  id="test"
                  label={"Employee"}
                  sx={{ mb: 2, width: "100%" }}
                  value={selectedEmployee?.id}
                  setValue={handleEmployeeChange}
                  placeholder={"Enter username"}
                  options={employeeList}
                  user
                />
              </div>
            )}
          </div>

          <div className="add-member-form-btn-wrapper def-btn-container">
            <button className="btn-style blank-btn cancel def-btn-height" onClick={handleMainClose}>
              Cancel
            </button>
            <button
              className="btn-style def-btn-height"
              onClick={onSubmit}
              disabled={saveLoading || !selectedEmployee?.id}>
              {saveLoading ? <Loading /> : "Add"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddExistingEmployeeModal;
