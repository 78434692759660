import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from "../axios";
import { actions as toastActions } from "../store/toast/toast.reducers";
import dayjs from 'dayjs';

export const useProductPrice = () => {
  const dispatch = useDispatch();

  const [productPrice, setProductPrice] = useState(0);

  useEffect(() => {
    const storageProductPrice = localStorage.getItem('productPrice');
    const productPriceDate = localStorage.getItem('productPriceDate');
    const curProductPriceDate = productPriceDate && dayjs(productPriceDate);
    const today = dayjs();
    const isProductPriceDateExpired = curProductPriceDate ? curProductPriceDate.isBefore(today, 'day') : false;

      axios
          .get("/subscription/subscription-price")
          .then((response) => {
              const curProductPrice = response.unit_amount / 100;
              setProductPrice(curProductPrice);
          })
          .catch((err) =>
              dispatch(
                  toastActions.toast({
                      code: "error",
                      message: err.response?.data?.message || err.message
                  })
              )
          );
  }, []);

  return { productPrice }
};
