import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmployeesContainer from "../components/employees/EmployeesContainer";
import TitleInfo from "./../components/common/TitleInfo";
import EmployeesModal from "../components/modals/EmployeesModal";
import { getEmployees } from "../store/employee/employee.selectors";
import { ReactComponent as Import } from "../assets/images/employees/import.svg";
import { ReactComponent as Export } from "../assets/images/employees/export.svg";
import axios from "../axios";
import { actions as toastActions } from "../store/toast/toast.reducers";
import {initiateFileDownloadFromBlob, isUserSourceRestricted} from "../helpers/helpers";
import { useMediaQuery } from "@mui/material";

const Employees = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:990px)");

  const [open, setOpen] = useState(false);
  const [isAddComplete, setIsAddComplete] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);

  const employees = useSelector(getEmployees);

  const isUserRestricted = isUserSourceRestricted();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleImport = (e) => {
    setIsImportLoading(true);

    const formData = new FormData();
    formData.append("file", e.currentTarget.files[0]);

    axios
      .post("employee/import", formData)
      .then((response) => {
        setIsAddComplete(true);
        response.success &&
          dispatch(toastActions.toast({ code: "success", message: response.message }));
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({
            code: "error",
            message: err.response?.data?.message || "Something went wrong"
          })
        )
      )
      .finally(() => setIsImportLoading(false));
  };

  const handleExport = () => {
    axios({
      method: "get",
      url: `/employee/download-csv`,
      responseType: "blob"
    })
      .then((response) => {
        const filename =
          process.env.REACT_APP_API_HOST !== "https://api.fleetwage.com/api"
            ? "test-employees"
            : "employees";
        initiateFileDownloadFromBlob(response, filename);
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({
            code: "error",
            message: err.response?.data?.message || "Something went wrong"
          })
        )
      );
  };

  return (
    <>
      <TitleInfo
        title="Employees"
        subTitle="All the employees"
        buttons={[
          !isUserRestricted && {
            name: "Import",
            class: "btn-style upload-fuel-btn employees-profile-img-upload employees-btn",
            icon: isMobile ? null : <Import />,
            loading: isImportLoading,
            input: (
              <input
                type="file"
                id="file-uploader"
                accept=".csv"
                disabled={isImportLoading}
                onChange={handleImport}
              />
            )
          },
          !isUserRestricted && {
            name: "Export",
            class: "btn-style upload-fuel-btn employees-btn",
            onClick: handleExport,
            icon: isMobile ? null : <Export />
          },
          {
            name: "+ Add Employee",
            class: "btn-style add-employee-btn",
            onClick: handleOpen
          }
        ]}
      />

      <EmployeesContainer isAddComplete={isAddComplete} setIsAddComplete={setIsAddComplete} />

      <EmployeesModal
        open={open}
        handleClose={handleClose}
        setIsEditComplete={employees.length < 11 ? setIsAddComplete : undefined}
      />
    </>
  );
};

export default Employees;
