import TableSortArrowsBlock from "../../common/TableSortArrowsBlock";
import SubscriptionBillingTableRow from "./SubscriptionBillingTableRow";

const SubscriptionBillingTable = ({ invoicingList, setSortItem, sortItem }) => {
  const handleSortChange = (sortItemName, type) =>
    setSortItem({
      name: sortItemName,
      direction: type
    });

  return (
    <div className="payroll-table billing">
      <table>
        <thead>
          <tr>
            <th>Invoice</th>
            <th>
              <div className="leaderboard-top-table-header-item">
                Created date{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  itemName="created"
                  activeSortedItem={sortItem}
                />
              </div>
            </th>
            <th>
              <div className="leaderboard-top-table-header-item">
                Amount{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  itemName="amount_paid"
                  activeSortedItem={sortItem}
                />
              </div>
            </th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {invoicingList.map((el) => (
            <SubscriptionBillingTableRow key={el.id} itemData={el} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubscriptionBillingTable;
