import React from "react";
import Box from "@mui/material/Box";
import Empty from "../../assets/images/run-payroll/empty-state.png";

const EmptyState = ({ title, paddingBottom }) => (
  <Box
    sx={{
      display: "grid",
      width: "100%",
      justifyContent: "center",
      justifyItems: "center",
      paddingBottom: paddingBottom ? "24px" : "0"
    }}>
    <img src={Empty} alt="no-payrolls" />
    <h3 style={{ color: "#717E94" }}>{title}</h3>
  </Box>
);

export default EmptyState;
