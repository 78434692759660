
import HomeBig from '../assets/images/settings/home-big.png';
import ChartBig from '../assets/images/settings/chart-big.png';
import ProfileBig from '../assets/images/settings/profile-big.png';
import BookBig from '../assets/images/settings/book.png';
import DollarBig from '../assets/images/settings/dollar-big.png';
import RankingBig from '../assets/images/settings/ranking-big.png';
import ExpensesIcon from "../assets/images/settings/expenses.png";
import AppBig from '../assets/images/settings/app-big.png';
import SettingsBig from '../assets/images/settings/settings-big.png';

import FedexLogo from '../assets/images/home/fedex-logo.png';
import AdpLogo from '../assets/images/home/adp-logo.png';
import GustoLogo from '../assets/images/home/gusto-logo.png';
import PaychexLogo from '../assets/images/home/paychex-logo.png';
import MailChimpLogo from '../assets/images/home/mailChimp-logo.png';
import QuickBooksLogo from '../assets/images/home/quickBooks-logo.png';

import ArrowUp from '../assets/images/arrow-up.png';
import RevenueChart from '../assets/images/revenue-chart.png';
import StopsChart from '../assets/images/stops-chart.png';
import ProfitMarginChart from '../assets/images/profit-margin-chart.png';

import UserBig from '../assets/images/employees/user-big.png';

import TotalCompaniesIcon from '../assets/images/insights/total-companies.png';
import TotalStationsIcon from '../assets/images/insights/total-stations.png';
import TotalEmployeesIcon from '../assets/images/insights/total-employees.png';

import CompaniesIconBig from '../assets/images/insights/companies-icon-big.png';

import CsvIconBig from '../assets/images/run-payroll/csv-icon-big.png';
import PdfIconBig from '../assets/images/run-payroll/pdf-icon-big.png';

import AccountIcon2Big from '../assets/images/settings/account-icon-2-big.png';

import { PendingActions } from '@mui/icons-material';

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'access_token',
  AUTHORIZATION_TOKEN: 'authorization_token'
};

export const analyticsItems = [
  {
    name: 'Overview',
    icon: HomeBig,
    url: '/'
  },
  {
    name: 'Leaderboard',
    icon: RankingBig,
    url: '/leaderboard'
  },
  {
    name: 'Expenses',
    icon: ExpensesIcon,
    url: '/expenses'
  }
];

export const employeesItems = [
  {
    name: 'Employees',
    icon: ProfileBig,
    url: '/employees'
  },
  {
    name: 'Wage Calculator',
    icon: DollarBig,
    url: '/run-payroll'
  }
];

export const supportItems = [
  {
    name: 'Help Center',
    icon: BookBig,
    url: 'http://fleetwage.com/help',
    redirect: true
  }
];

export const organizationsItems = [
  {
    name: 'Insights',
    icon: ChartBig,
    url: '/admin/insights'
  },
  {
    name: 'Pending Requests',
    icon: <PendingActions sx={{ mr: '13px', width: '21px', height: '21px' }} />,
    url: '/admin/pending-requests',
    mui: true
  },
  {
    name: 'Organizations',
    icon: AccountIcon2Big,
    url: '/admin/organizations'
  }
];

export const othersItems = [
  {
    name: 'Integrations',
    icon: AppBig,
    url: '/app'
  },
  // {
  //   name: 'Training',
  //   icon: OtherChartBig,
  //   url: '/app'
  // },
  // {
  //   name: 'Handbook',
  //   icon: BookBig,
  //   url: '/app'
  // },
  {
    name: 'Settings',
    icon: SettingsBig,
    url: '/settings'
  }
];

export const companyIcons = {
  fedex: FedexLogo,
  adp: AdpLogo,
  gusto: GustoLogo,
  paychex: PaychexLogo,
  mailChimp: MailChimpLogo,
  quickBooks: QuickBooksLogo
};

export const totalBlocks = [
  {
    title: 'Total Revenue',
    subTitle: '$191,448',
    diffIcon: ArrowUp,
    chartIcon: RevenueChart,
    percent: 1.3
  },
  {
    title: 'Total Stops',
    subTitle: '59,751',
    diffIcon: ArrowUp,
    chartIcon: StopsChart,
    percent: 2.1
  }, {
    title: 'Profit Margin',
    subTitle: '27.38%',
    diffIcon: ArrowUp,
    chartIcon: ProfitMarginChart,
    percent: 0.7
  },
];

export const userList = [
  {
    id: 1,
    image: UserBig,
    firstName: 'Dmitriy',
    lastName: 'Popello',
    company: 'Zev Express Inc',
    station: 'King of Prussia',
    fedexId: 7953645,
    employeeId: 10310,
    payAdjustment: 'Daily'
  },
  {
    id: 2,
    image: UserBig,
    firstName: 'Jeffrey',
    lastName: 'Yearick',
    company: 'Zev Express Inc',
    station: 'Prussia',
    fedexId: 7101882,
    employeeId: 10460,
    payAdjustment: 'Daily'
  },
  {
    id: 3,
    image: UserBig,
    firstName: 'Dmitriy',
    lastName: 'Popello',
    company: 'Zev Express Inc',
    station: 'King of Prussia',
    fedexId: 7953645,
    employeeId: 10310,
    payAdjustment: 'Weekly'
  },
  {
    id: 4,
    image: UserBig,
    firstName: 'Jeffrey',
    lastName: 'Yearick',
    company: 'Zev Express Inc',
    station: 'Prussia',
    fedexId: 7101882,
    employeeId: 10460,
    payAdjustment: 'Weekly'
  },
  {
    id: 5,
    image: UserBig,
    firstName: 'Jeffrey',
    lastName: 'Yearick',
    company: 'Zev Express Inc',
    station: 'Prussia',
    fedexId: 7101882,
    employeeId: 10460,
    payAdjustment: 'Daily'
  },
  {
    id: 6,
    image: UserBig,
    firstName: 'Dmitriy',
    lastName: 'Popello',
    company: 'Zev Express Inc',
    station: 'King of Prussia',
    fedexId: 7953645,
    employeeId: 10310,
    payAdjustment: 'Daily'
  },
  {
    id: 7,
    image: UserBig,
    firstName: 'Dmitriy',
    lastName: 'Popello',
    company: 'Zev Express Inc',
    station: 'King of Prussia',
    fedexId: 7953645,
    employeeId: 10310,
    payAdjustment: 'Daily'
  },
  {
    id: 8,
    image: UserBig,
    firstName: 'Jeffrey',
    lastName: 'Yearick',
    company: 'Zev Express Inc',
    station: 'Prussia',
    fedexId: 7101882,
    employeeId: 10460,
    payAdjustment: 'Weekly'
  }
];

export const insightsTotal = [
  {
    name: 'Total Companies',
    icon: TotalCompaniesIcon,
    count: 4
  },
  {
    name: 'Total Stations',
    icon: TotalStationsIcon,
    count: 10
  },
  {
    name: 'Total Employees',
    icon: TotalEmployeesIcon,
    count: 108
  }
];

export const insightsTableArray = [
  {
    id: 1,
    name: 'Zev Express Inc',
    stations: 2,
    revenue: '43,612.59',
    logo: CompaniesIconBig
  },
  {
    id: 2,
    name: 'Urartu Logistics Inc',
    stations: 4,
    revenue: '60,200.35',
    logo: CompaniesIconBig
  },
  {
    id: 3,
    name: 'Main Line Logistics Inc',
    stations: 3,
    revenue: '72,211.42',
    logo: CompaniesIconBig
  },
  {
    id: 4,
    name: 'Next Mile Inc',
    stations: 1,
    revenue: '17,042.64',
    logo: CompaniesIconBig
  }
];

export const settingsCompanies = [
  {
    id: 1,
    name: 'Zev Express Inc',
    stations: 2,
    revenue: '43,612.59',
    logo: CompaniesIconBig
  },
  {
    id: 2,
    name: 'Urartu Logistics Inc',
    stations: 4,
    revenue: '60,200.35',
    logo: CompaniesIconBig
  },
  {
    id: 3,
    name: 'Main Line Logistics Inc',
    stations: 3,
    revenue: '72,211.42',
    logo: CompaniesIconBig
  },
  {
    id: 4,
    name: 'Next Mile Inc',
    stations: 1,
    revenue: '17,042.64',
    logo: CompaniesIconBig
  }
];

export const runPayrollDocuments = [
  {
    id: 1,
    name: 'GF_STATEMENT_DETAILS_ISP.csv',
    records: 234,
    uploadedBy: 'Vazgen Avakyan',
    time: 'May 16, 2023 9:23 pm',
    logo: CsvIconBig
  },
  {
    id: 2,
    name: 'GF_STATEMENT_DETAILS_ISP.csv',
    records: 234,
    uploadedBy: 'Vazgen Avakyan',
    time: 'May 16, 2023 9:23 pm',
    logo: CsvIconBig
  },
  {
    id: 3,
    name: 'GF_STATEMENT_DETAILS_ISP.csv',
    records: 234,
    uploadedBy: 'Vazgen Avakyan',
    time: 'May 16, 2023 9:23 pm',
    logo: PdfIconBig
  },
  {
    id: 4,
    name: 'GF_STATEMENT_DETAILS_ISP.csv',
    records: 234,
    uploadedBy: 'Vazgen Avakyan',
    time: 'May 16, 2023 9:23 pm',
    logo: CsvIconBig
  },
  {
    id: 5,
    name: 'GF_STATEMENT_DETAILS_ISP.csv',
    records: 234,
    uploadedBy: 'Vazgen Avakyan',
    time: 'May 16, 2023 9:23 pm',
    logo: CsvIconBig
  },
  {
    id: 6,
    name: 'GF_STATEMENT_DETAILS_ISP.csv',
    records: 234,
    uploadedBy: 'Vazgen Avakyan',
    time: 'May 16, 2023 9:23 pm',
    logo: CsvIconBig
  },
];

export const runPayrollPreviewData = [
  {
    id: 1,
    image: UserBig,
    name: 'Dmitriy Popello',
    company: 'Zev Express Inc',
    terminal: 'King of Prussia',
    fedExId: 7953645,
    employeeId: 10310,
    basePay: '500.00',
    bonus: '50.00',
    totalPay: '970.00'
  },
  {
    id: 2,
    image: UserBig,
    name: 'Dmitriy Popello',
    company: 'Zev Express Inc',
    terminal: 'King of Prussia',
    fedExId: 7953645,
    employeeId: 10310,
    basePay: '500.00',
    bonus: '50.00',
    totalPay: '970.00'
  },
  {
    id: 3,
    image: UserBig,
    name: 'Dmitriy Popello',
    company: 'Zev Express Inc',
    terminal: 'King of Prussia',
    fedExId: 7953645,
    employeeId: 10310,
    basePay: '500.00',
    bonus: '50.00',
    totalPay: '970.00'
  },
  {
    id: 4,
    image: UserBig,
    name: 'Dmitriy Popello',
    company: 'Zev Express Inc',
    terminal: 'King of Prussia',
    fedExId: 7953645,
    employeeId: 10310,
    basePay: '500.00',
    bonus: '50.00',
    totalPay: '970.00'
  },
  {
    id: 5,
    image: UserBig,
    name: 'Dmitriy Popello',
    company: 'Zev Express Inc',
    terminal: 'King of Prussia',
    fedExId: 7953645,
    employeeId: 10310,
    basePay: '500.00',
    bonus: '50.00',
    totalPay: '970.00'
  },
  {
    id: 6,
    image: UserBig,
    name: 'Dmitriy Popello',
    company: 'Zev Express Inc',
    terminal: 'King of Prussia',
    fedExId: 7953645,
    employeeId: 10310,
    basePay: '500.00',
    bonus: '50.00',
    totalPay: '970.00'
  }
];

export const userRoles = {
  SUPER_ADMIN: 'superadmin',
  ORGANIZATION_ADMIN: 'organization_admin',
  MEMBER: 'member'
};
