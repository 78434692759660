import { useElements, useStripe } from "@stripe/react-stripe-js";
import Loading from "./../../common/Loading";
import { useMediaQuery } from "@mui/material";

const SubscriptionModalButtons = ({
  handleSubmit,
  saveLoading,
  handleClose,
  onSubmit,
  isFirstCard
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const isMobile = useMediaQuery("(max-width:990px)");

  return (
    <div className="add-member-form-btn-wrapper">
      <button className="btn-style blank-btn add-popup-cancel" onClick={handleClose}>
        Cancel
      </button>
      <button
        className="btn-style confirm-modal-btn company company-btn"
        onClick={handleSubmit(onSubmit.bind(null, stripe, elements))}
        disabled={saveLoading}>
        {saveLoading ? (
          <Loading />
        ) : isFirstCard ? (
          "Activate"
        ) : isMobile ? (
          "Save"
        ) : (
          "Save new payment method"
        )}
      </button>
    </div>
  );
};

export default SubscriptionModalButtons;
