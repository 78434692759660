import "../assets/css/service-unavailable.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Planets } from "../assets/images/503/planets.svg";
import axios from "../axios";

const ServiceUnavailable = () => {
  const nav = useNavigate();

  useEffect(() => {
    const handler = setInterval(() => {
      axios
        .get("/auth/user")
        .then((response) => {
          if (response) {
            const prevPage = localStorage.getItem("prevPage");

            nav(prevPage || "/");
            localStorage.setItem("prevPage", "/");
          }
        })
        .catch((err) => console.log(err));
    }, 10000);

    return () => clearTimeout(handler);
  }, []);

  return (
    <div className="service-unavailable">
      <div className="service-unavailable-content">
        <Planets />
        <div className="service-unavailable-title">
          Site is temporarily under maintenance for a quick upgrade
        </div>
        <div className="service-unavailable-subtitle">
          Perfect time for a quick coffee break! We`ll be back online in 5 minutes or less. Thank
          you for your patience
        </div>
      </div>
    </div>
  );
};

export default ServiceUnavailable;
