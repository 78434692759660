import Loading from "./Loading";

const TitleInfo = ({ title, subTitle, buttons = null, jsxItem = null, id }) => {
  return (
    <div className="top-title-bar" id={id}>
      <div className="title-sec">
        <h2>{title}</h2>
        <h5>{subTitle}</h5>
      </div>

      <div className="btn-sec">
        {buttons &&
          buttons.map((el, index) => (
            <button
              key={index}
              onClick={el.onClick}
              className={el.class}
              disabled={el.loading || el.disabled}>
              {el.loading ? (
                <Loading />
              ) : el.icon ? (
                <>
                  {el.icon} {el.name}
                </>
              ) : (
                el.name
              )}
              {el.input && el.input}
            </button>
          ))}
        {jsxItem && jsxItem}
      </div>
    </div>
  );
};

export default TitleInfo;
