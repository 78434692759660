import { ReactComponent as Edit } from "../../../assets/images/settings/edit.svg";
import { ReactComponent as Delete } from "../../../assets/images/run-payroll/delete-filled.svg";
import { ReactComponent as PayrollTaxes } from "../../../assets/images/fixed-expenses-settings/payroll_taxes.svg";
import { ReactComponent as WorkerCompensation } from "../../../assets/images/fixed-expenses-settings/worker_compensation.svg";
import { ReactComponent as PayrollUcEr } from "../../../assets/images/fixed-expenses-settings/payroll_uc_er.svg";
import { ReactComponent as HealthLifeInsurance } from "../../../assets/images/fixed-expenses-settings/health_life_insurance.svg";
import { ReactComponent as AvantInsurance } from "../../../assets/images/fixed-expenses-settings/avant_insurance.svg";
import { ReactComponent as ElpiInsurance } from "../../../assets/images/fixed-expenses-settings/elpi_insurance.svg";
import { ReactComponent as ScannersDriver } from "../../../assets/images/fixed-expenses-settings/scanners_driver.svg";
import { ReactComponent as CustomExpenses } from "../../../assets/images/fixed-expenses-settings/custom-expenses.svg";
import { formatMoney } from "./../../../utils";

const SettingsFixedExpensesContentItem = ({
  itemData,
  handleChangeItem,
  handleDelete,
  handleEditCustomOpen
}) => {

  const defaultFieldIcons = {
    payroll_taxes: PayrollTaxes,
    worker_compensation: WorkerCompensation,
    payroll_uc_er: PayrollUcEr,
    health_life_insurance: HealthLifeInsurance,
    avant_insurance: AvantInsurance,
    elpi_insurance: ElpiInsurance,
    scanners_driver: ScannersDriver,
  };

  const getIconComponent = (fieldName) => defaultFieldIcons[fieldName] || CustomExpenses;

  const IconComponent = getIconComponent(itemData.name);

  const isPerEmployee = itemData.type === "per_employee";

  return (
    <div className="settings-expenses-content-item">
      <div className="settings-expenses-content-item-left">
          {itemData.id ? <IconComponent /> : itemData.icon}
          <div>{itemData.title || itemData.name}</div>
      </div>

      <div className="settings-expenses-content-item-right">
        <div className="settings-expenses-content-item-value">
          {itemData.type === "percentage"
            ? `${itemData.cost} %`
            : formatMoney(itemData.cost, true)}{" "}
          {isPerEmployee && " /"} {isPerEmployee && <div>Employee</div>}
        </div>

        <div className="expenses-btns-wrapper">
          <button
            className="companies-btn edit"
            onClick={
              itemData.id
                ? handleEditCustomOpen.bind(null, itemData)
                : handleChangeItem.bind(null, itemData)
            }>
            <Edit />
          </button>
          {itemData.id && (
            <button className="companies-btn delete" onClick={handleDelete.bind(null, itemData)}>
              <Delete className="companies-delete" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsFixedExpensesContentItem;
