import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import axios from "../../axios";
import { actions as toastActions } from "../../store/toast/toast.reducers";
import Loading from "./../common/Loading";
import TitleInfo from "../common/TitleInfo";
import SettingsCompaniesList from "./companies/SettingsCompaniesList";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import CompanyModal from "../modals/CompanyModal";
import {isUserSourceRestricted} from "../../helpers/helpers";

const SettingsCompanies = () => {
  const dispatch = useDispatch();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);

  const [addOpen, setAddOpen] = useState(false);
  const [editedCompany, setEditedCompany] = useState();
  const [isAddLoading, setIsAddLoading] = useState(false);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [deletedCompany, setDeletedCompany] = useState();

  const [stationCount, setStationCount] = useState(0);

  const isUserRestricted = isUserSourceRestricted();

  useEffect(() => {
    if (!isNeedUpdate) {
      fetchCompanies();
      fetchStationsCount();
    }

    if (isNeedUpdate) {
      setIsNeedUpdate(false);
    }
  }, [isNeedUpdate]);

  const fetchCompanies = () => {
    setLoading(true);

    axios
      .get("/companies")
      .then((response) => {
        if (response.success) {
          setCompaniesList(response.data.data);
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  const fetchStationsCount = () => {
    axios
      .get("/station-count")
      .then((response) => {
        if (response.success) {
          setStationCount(response.data);
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      );
  };

  const handleAddOpen = () => setAddOpen(true);

  const handleAddClose = () => {
    setAddOpen(false);
    setEditedCompany();
  };

  const handleCloseDeleteModal = () => setDeletedCompany();

  const handleDelete = (isChecked, setIsChecked) => {
    setIsDeleteLoading(true);

    const body = {
      ...(isChecked && !!deletedCompany?.employees_count ? { delete_employees: 1 } : {})
    };

    axios
      .delete(`/companies/${deletedCompany.id}`, { data: body })
      .then((response) => {
        if (response.success) {
          handleCloseDeleteModal();
          setIsNeedUpdate(true);
          dispatch(toastActions.toast({ code: "success", message: "Company deleted success" }));
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => {
        setIsDeleteLoading(false);
        isChecked && setIsChecked(false);
      });
  };

  const handleOpenEditModal = (item) => setEditedCompany(item);

  const handleEdit = (values, handleCurClose) => {
    setIsAddLoading(true);

    const formData = new FormData();

    for (const property in values) {
      formData.append(property, values[property]);
    }

    if (editedCompany) {
      formData.append("_method", "PUT");

      const url = isUserRestricted
          ? `/package-route/companies/${editedCompany.id}`
          : `/companies/${editedCompany.id}`;

      axios
        .post(url, formData)
        .then((response) => {
          if (response.success) {
            handleAddClose();
            handleCurClose && handleCurClose();
            setIsNeedUpdate(true);
          }
        })
        .catch((err) =>
          dispatch(
            toastActions.toast({
              code: "error",
              message: err.response?.data?.message || err.message
            })
          )
        )
        .finally(() => setIsAddLoading(false));
    } else {
      axios
        .post("/companies", formData)
        .then((response) => {
          if (response.success) {
            handleAddClose();
            handleCurClose && handleCurClose();
            setIsNeedUpdate(true);
          }
        })
        .catch((err) =>
          dispatch(
            toastActions.toast({
              code: "error",
              message: err.response?.data?.message || err.message
            })
          )
        )
        .finally(() => setIsAddLoading(false));
    }
  };

  const handleOpenDeleteModal = (item, e) => {
    e.preventDefault();

    setDeletedCompany(item);
  };

  return (
    <div className="profile-companies settings-tabs">
    {!isUserRestricted && (
        <TitleInfo
            title="Company"
            subTitle="Manage Company, Station, Contract Service Area and Work Area"
            buttons={[
                {
                    name: "+ Add Company",
                    class: "btn-style",
                    disabled: companiesList.length >= 10,
                    onClick: handleAddOpen
                }
            ]}
        />
    )}

      {loading ? (
        <Loading />
      ) : (
        <div className="table-scroll-mobile payroll-table companies">
          <SettingsCompaniesList
            companiesList={companiesList}
            handleDelete={handleOpenDeleteModal}
            handleEdit={handleOpenEditModal}
            stationCount={stationCount}
          />
        </div>
      )}

      <CompanyModal
        open={!!editedCompany || addOpen}
        handleClose={handleAddClose}
        editedCompany={editedCompany}
        handleConfirm={handleEdit}
        isLoading={isAddLoading}
        isUserRestricted={isUserRestricted}
      />

      <ConfirmDeleteModal
        open={!!deletedCompany}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleDelete}
        text="Are you sure delete this Company?"
        subtitle={
          deletedCompany?.employees_count
            ? `There are ${deletedCompany?.employees_count} employees on this Company, do you want to proceed?`
            : null
        }
        checkbox={!!deletedCompany?.employees_count}
        isLoading={isDeleteLoading}
      />
    </div>
  );
};

export default SettingsCompanies;
