import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useNavigate, useParams } from "react-router-dom";
import { DragHandle } from "./DragHandle";
import { formatMoney, formatNumber } from "../../utils";

export const RunPayrollPreviewDraggableRow = ({ row }) => {
  const nav = useNavigate();
  const { id } = useParams();

  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: `${row.order}`
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transform ? transition : "none"
  };

  const {
    total_w2,
    total_charges,
    total_fuel,
    total_stops,
    station_name,
    company_name,
    total_expenses,
    profit,
    exp_stop,
    per_stop
  } = row;

  const handleGoToStatement = () => nav(`/run-payroll-preview/${id}/${row.id}`);

  return (
    <tr ref={setNodeRef} style={style}>
      {isDragging ? (
        <>
          <td style={{ background: "rgba(127, 207, 250, 0.3)" }}>&nbsp;</td>
          <td style={{ background: "rgba(127, 207, 250, 0.3)" }}>&nbsp;</td>
          <td style={{ background: "rgba(127, 207, 250, 0.3)" }}>&nbsp;</td>
          <td style={{ background: "rgba(127, 207, 250, 0.3)" }}>&nbsp;</td>
          <td style={{ background: "rgba(127, 207, 250, 0.3)" }}>&nbsp;</td>
          <td style={{ background: "rgba(127, 207, 250, 0.3)" }}>&nbsp;</td>
          <td style={{ background: "rgba(127, 207, 250, 0.3)" }}>&nbsp;</td>
          <td style={{ background: "rgba(127, 207, 250, 0.3)" }}>&nbsp;</td>
          <td style={{ background: "rgba(127, 207, 250, 0.3)" }}>&nbsp;</td>
          <td style={{ background: "rgba(127, 207, 250, 0.3)" }}>&nbsp;</td>
          <td style={{ background: "rgba(127, 207, 250, 0.3)" }}>&nbsp;</td>
        </>
      ) : (
        <>
          <td style={{ width: "30px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <DragHandle {...attributes} {...listeners} />
            </div>
          </td>
          <td onClick={handleGoToStatement}>{company_name}</td>
          <td onClick={handleGoToStatement}>{station_name}</td>
          <td onClick={handleGoToStatement}>{formatMoney(exp_stop || 0, true)}</td>
          <td onClick={handleGoToStatement}>{formatNumber(total_stops)}</td>
          <td onClick={handleGoToStatement}>{formatMoney(total_fuel, true)}</td>
          <td onClick={handleGoToStatement}>{formatMoney(total_expenses, true)}</td>
          <td onClick={handleGoToStatement}>{formatMoney(total_w2, true)}</td>
          <td onClick={handleGoToStatement}>{formatMoney(total_charges, true)}</td>
          <td onClick={handleGoToStatement}>{formatMoney(per_stop || 0, true)}</td>
          <td onClick={handleGoToStatement}>{formatMoney(profit, true)}</td>
        </>
      )}
    </tr>
  );
};
