import { useState } from "react";
import { ReactComponent as PaymentMethodIcon } from "../../../assets/images/settings/payment-method.svg";
import { ReactComponent as NoPayment } from "../../../assets/images/settings/no-payment.svg";
import { ReactComponent as AddIcon } from "../../../assets/images/settings/add.svg";
import Loading from "../../common/Loading";
import SubscriptionAddPaymentMethodModal from "../../modals/SubscriptionAddPaymentMethodModal";
import SubscriptionPaymentMethodItem from "./SubscriptionPaymentMethodItem";

const SubscriptionPaymentMethod = ({ isLoading, cards, setIsNeedRefresh }) => {
  const [isOpenAddSubscription, setIsOpenAddSubscription] = useState(false);

  const handleCloseAddSubscription = () => setIsOpenAddSubscription(false);
  const handleOpenAddSubscription = () => setIsOpenAddSubscription(true);

  return (
    <div className="subscription-plan">
      <div className="subscription-plan-header">
        <div className="subscription-plan-header-icon">
          <PaymentMethodIcon />
          <div>Payment</div>
        </div>
        {(!cards.length || cards.length < 2) && (
          <div className="subscription-plan-header-add" onClick={handleOpenAddSubscription}>
            <AddIcon /> Add new method
          </div>
        )}
      </div>

      <div className="subscription-plan-container payment-method">
        {isLoading ? (
          <Loading />
        ) : !cards.length ? (
          <div className="payment-method-empty">
            <NoPayment />
            <div>No payment method</div>
          </div>
        ) : (
          <div className="subscription-payment-method-items">
            {cards.map((el) => (
              <SubscriptionPaymentMethodItem
                key={el.id}
                setIsNeedRefresh={setIsNeedRefresh}
                card={el}
              />
            ))}
          </div>
        )}
      </div>

      <SubscriptionAddPaymentMethodModal
        open={isOpenAddSubscription}
        handleClose={handleCloseAddSubscription}
        setIsNeedRefresh={setIsNeedRefresh}
        isFirstCard={!cards.length}
      />
    </div>
  );
};

export default SubscriptionPaymentMethod;
