import SettingsCompaniesRow from "./SettingsCompaniesRow";

const SettingsCompaniesList = ({ companiesList, handleDelete, handleEdit, stationCount }) => {
  return (
    <div className="companies-list">
      <ul>
        {companiesList.map((el) => (
          <SettingsCompaniesRow
            key={el.id}
            itemData={el}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            stationCount={stationCount}
          />
        ))}
      </ul>
    </div>
  );
};

export default SettingsCompaniesList;
