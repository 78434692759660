import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Fragment } from "react";
import "../../assets/css/common/payroll-top-total.css";
import { ReactComponent as Danger } from "../../assets/images/run-payroll-preview/danger.svg";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#1D1858",
    boxShadow: "0px 0px 24px 0px #0000001A",
    fontSize: 12,
    borderRadius: "3px",
    fontWeight: 400,
    [`& .MuiTooltip-arrow`]: {
      color: theme.palette.common.white
    }
  }
}));

const PayrollTopTotal = ({ totalStats, isShowFuelIcon }) => {
  return (
    <div className="payroll-top-total">
      {totalStats.map((el, index) => (
        <Fragment key={index}>
          <div className="payroll-top-total-item">
            <div className="payroll-top-total-name-wrapper">
              {isShowFuelIcon && el.name === "Fuel" && el.count === "$0.00" && (
                <LightTooltip title="Fuel Data is Missing." arrow>
                  <Danger />
                </LightTooltip>
              )}
              {el.alert && (
                <LightTooltip title={el.alert} arrow>
                  <Danger />
                </LightTooltip>
              )}
              {el.name}
            </div>

            <div className="payroll-top-total-count">{el.count}</div>
            <div className="payroll-top-total-percentage">
              {el.percentage ? `${el.percentage} %` : ""}
            </div>
          </div>
          <div className="payroll-top-total-item-divider" />
        </Fragment>
      ))}
    </div>
  );
};

export default PayrollTopTotal;
