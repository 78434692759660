import React from "react";
import Empty from "../../assets/images/run-payroll/empty-state.png";
import Box from "@mui/material/Box";

const EmptyState = () => {
  return (
    <Box
      sx={{
        display: "grid",
        width: "100%",
        justifyContent: "center",
        justifyItems: "center"
      }}>
      <img src={Empty} alt="no-payrolls" />
      <h3 style={{ color: "#717E94" }}>No added files</h3>
      <h5 style={{ color: "#333645" }}>You currently have no files uploaded</h5>
    </Box>
  );
};

export default EmptyState;
