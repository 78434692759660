import { useLocation, useNavigate } from "react-router-dom";

const LeftMenuItem = ({ menuItem, isCollapsed }) => {
  const nav = useNavigate();
  const location = useLocation();
  const { icon, name, url, mui, redirect } = menuItem;

  const ifActive = location.pathname === url;

  const handleNavigate = () => (redirect ? window.open(url, "_blank") : nav(url));

  return (
    <li>
      <button
        onClick={handleNavigate}
        className={ifActive ? "active" : ""}
        style={{
          whiteSpace: "nowrap",
          width: isCollapsed ? "52px" : "100%",
          margin: isCollapsed ? "auto" : "0"
        }}>
        {mui ? icon : <img src={icon} alt="" />}

        {!isCollapsed && name}
      </button>
    </li>
  );
};

export default LeftMenuItem;
