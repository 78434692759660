import { useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { actions as toastActions } from "../../store/toast/toast.reducers";
import Loading from "../common/Loading";
import axios from "../../axios";

const styles = {
  error: {
    "& div input": {
      borderColor: "red !important"
    }
  }
};

const schema = yup.object().shape({
  email: yup.string().email("Invalid format").required("Email is required!"),
  password: yup
    .string()
    .required("Password is required!")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  password_confirmation: yup
    .string()
    .required("Confirm Password is required!")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
});

const ResetForm = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const nav = useNavigate();

  const [loading, setLoading] = useState(false);

  const useQuery = () => {
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
      password: "",
      password_confirmation: ""
    },
    resolver: yupResolver(schema)
  });

  const { password, password_confirmation } = watch();

  useEffect(() => {
    const emailFromQuery = query.get("email");
    if (emailFromQuery) {
      setValue("email", emailFromQuery, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [search]);

  const onSubmit = (values) => {
    if (password !== password_confirmation) {
      const message = "Password and Confirm Password do not match. Enter again";

      dispatch(toastActions.toast({ code: "error", message: message }));
      setValue("password", "", {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("password_confirmation", "", {
        shouldValidate: true,
        shouldDirty: true
      });
      return;
    }

    setLoading(true);

    axios
      .post("/auth/password/reset", {
        ...values,
        token: query.get("token")
      })
      .then((res) => {
        if (res.errors) {
          dispatch(toastActions.toast({ code: "error", message: res.message }));
          reset();
          return;
        }

        dispatch(toastActions.toast({ code: "success", message: res.message }));
        nav("/login");
        return;
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || `${Object.values(err.response.data.errors).join("")}` || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <div className="inner-sec">
      <h1>Reset Password</h1>
      <br></br>
      <form className="login-form">
        <div className="input-items">
          <label className={errors.email?.message && "error"}>Email Address *</label>
          <TextField
            sx={errors.primary_station?.message ? styles.error : {}}
            type="email"
            name="email"
            required
            {...register("email")}
            error={touchedFields.email && !!errors.email?.message}
            helperText={errors.email?.message}
          />
        </div>
        <div className="input-items">
          <label className={errors.password?.message && "error"}>Password *</label>
          <TextField
            sx={errors.primary_station?.message ? styles.error : {}}
            type="password"
            name="password"
            required
            {...register("password")}
            error={touchedFields.password && !!errors.password?.message}
            helperText={errors.password?.message}
          />
        </div>
        <div className="input-items">
          <label className={errors.password_confirmation?.message && "error"}>
            Confirm Password *
          </label>
          <TextField
            sx={errors.primary_station?.message ? styles.error : {}}
            type="password"
            name="password_confirmation"
            required
            {...register("password_confirmation")}
            error={touchedFields.password_confirmation && !!errors.password_confirmation?.message}
            helperText={errors.password_confirmation?.message}
          />
        </div>
        <div className="submit-btn">
          {loading ? (
            <Loading />
          ) : (
            <input
              type="submit"
              value="Reset Password"
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default ResetForm;
