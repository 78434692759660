import { Box, Divider, Drawer, Stack, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { ReactComponent as Logo } from "../../assets/images/fleet-wage-logo-rev.svg";
import { items } from "./config";
import SideNavItem from "./SideNavItem";
import Scrollbar from "./Scrollbar";

const SideNav = (props) => {
  const { open, onClose } = props;
  const { pathname } = useLocation();
  const nav = useNavigate();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const handleLogoClick = () => nav("/admin/organizations");

  const content = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%"
        },
        "& .simplebar-scrollbar:before": {
          background: "neutral.400"
        }
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%"
        }}>
        <Box sx={{ p: 3 }}>
          <Box
            onClick={handleLogoClick}
            sx={{
              display: "inline-flex",
              height: 32,
              width: 32
            }}>
            <a href="/" className="logo-item">
              <Logo />
            </a>
          </Box>
        </Box>
        <Divider sx={{ borderColor: "neutral.700" }} />
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3
          }}>
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: "none",
              p: 0,
              m: 0
            }}>
            {items.map((item) => {
              const active = item.path ? pathname === item.path : false;

              return (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                />
              );
            })}
          </Stack>
        </Box>
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.800",
            color: "common.white",
            width: 280
          }
        }}
        variant="permanent">
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.800",
          color: "common.white",
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary">
      {content}
    </Drawer>
  );
};

export default SideNav;
