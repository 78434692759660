import "../../assets/css/modal.css";

import { FormControlLabel, Modal, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/settings/add.svg";
import { ReactComponent as MasterCard } from "../../assets/images/settings/master-card.svg";
import { ReactComponent as Visa } from "../../assets/images/settings/visa.svg";
import axios from "../../axios";
import { actions as toastActions } from "../../store/toast/toast.reducers";
import Loading from "../common/Loading";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import { actions } from "../../store/auth/auth.reducers";
import { getCouponCode } from "../../store/data/data.selectors";

const SubscriptionModal = ({
  open,
  handleClose,
  setIsNeedRefresh,
  handleAddCard,
  cards,
  isLoading
}) => {
  const dispatch = useDispatch();

  const couponCode = useSelector(getCouponCode);

  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();
  const [isDefaultLoading, setIsDefaultLoading] = useState();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [deleteCardData, setDeleteCardData] = useState();

  useEffect(() => {
    if (cards.length) {
      const defaultCard = cards.find((el) => el.active) || cards[0];

      setPaymentMethod(defaultCard);
    }
  }, [cards]);

  const handleSubmit = () => {
    setSubscribeLoading(true);

    axios
      .post("/subscription/subscribe", {
        paymentMethod: paymentMethod.id,
        ...(couponCode && { coupon_code: couponCode })
      })
      .then((res) => {
        if (res.success) {
          dispatch(toastActions.toast({ code: "success", message: res.message }));
          dispatch(actions.setUserIsSubscribed(true));
          handleClose();
        } else {
          dispatch(toastActions.toast({ code: "error", message: res.data.message }));
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setSubscribeLoading(false));
  };

  const handleAdd = () => {
    handleClose();
    handleAddCard();
  };

  const handleSetDefault = (card) => {
    setIsDefaultLoading(card.id);

    axios
      .post("subscription/cards/set-default", { payment_method_id: card.id })
      .then((res) => {
        if (res.success) {
          dispatch(toastActions.toast({ code: "success", message: res.message }));
          setIsNeedRefresh(true);
        } else {
          dispatch(toastActions.toast({ code: "error", message: res.data.message }));
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setIsDefaultLoading());
  };

  const handleDelete = () => {
    setIsDeleteLoading(true);

    axios
      .delete(`/subscription/cards/${deleteCardData.id}`)
      .then((res) => {
        if (res.success) {
          dispatch(toastActions.toast({ code: "success", message: res.message }));
          setIsNeedRefresh(true);
          handleCloseDeleteModal();
        } else {
          dispatch(toastActions.toast({ code: "error", message: res.data.message }));
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setIsDeleteLoading(false));
  };

  const handleOpenDeleteModal = (card) => {
    setIsOpenDeleteModal(true);
    setDeleteCardData(card);
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
    setDeleteCardData();
  };

  const handlePaymentMethodChange = (e) => {
    const selectedPaymentMethod = cards.find((el) => el.id === e.target.value);
    setPaymentMethod(selectedPaymentMethod);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div className="confirm-modal subscriptions-subscribe-modal">
          <div className="confirm-modal-wrapper add-member-modal">
            <div className="add-member-modal-header">
              <div className="title-sec">
                <h2>Payment method</h2>
              </div>

              <div className="confirm-modal-close-wrapper" onClick={handleClose}>
                <CloseIcon />
              </div>
            </div>

            <div className="subscriptions-subscribe-container">
              <div className="subscriptions-subscribe-container-detail">
                <div>Card details</div>
                <div>Select payment method</div>
              </div>

              <div className="subscriptions-subscribe-cards">
                {isLoading ? (
                  <Loading />
                ) : (
                  paymentMethod && (
                    <>
                      <RadioGroup
                        value={paymentMethod?.id}
                        onChange={handlePaymentMethodChange}
                        sx={{ width: "100%" }}>
                        {cards.map((el) => (
                          <FormControlLabel
                            key={el.id}
                            value={el.id}
                            control={<Radio />}
                            labelPlacement="start"
                            className="subscriptions-subscribe-card-container"
                            label={
                              <div className="subscriptions-subscribe-card">
                                <div className="subscription-payment-method-item-info">
                                  {el.card.brand === "visa" ? (
                                    <Visa className="subscription-payment-method-item-icon" />
                                  ) : (
                                    <MasterCard className="subscription-payment-method-item-icon" />
                                  )}
                                  <div className="subscription-payment-method-item-info-text">
                                    <div className="subscription-payment-method-item-info-ending">
                                      Card ending in {el.card.last4}
                                    </div>
                                    <div>
                                      Expired {el.card.exp_month}/{el.card.exp_year}
                                    </div>
                                    <div>Card holder name: {el.billing_details.name}</div>
                                    {cards.length > 1 && (
                                      <div className="subscriptions-subscribe-card-btns">
                                        <div
                                          className={`subscriptions-subscribe-card-btn ${
                                            el.active && "disabled"
                                          }`}
                                          onClick={handleSetDefault.bind(null, el)}>
                                          {isDefaultLoading === el.id ? (
                                            <Loading />
                                          ) : el.active ? (
                                            "Default"
                                          ) : (
                                            "Set as default"
                                          )}
                                        </div>

                                        <div
                                          className="subscriptions-subscribe-card-btn delete"
                                          onClick={handleOpenDeleteModal.bind(null, el)}>
                                          Delete
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            }
                          />
                        ))}
                      </RadioGroup>
                      {(!cards.length || cards.length < 2) && (
                        <div className="subscriptions-subscribe-modal-add" onClick={handleAdd}>
                          <AddIcon /> Add new payment method
                        </div>
                      )}
                    </>
                  )
                )}
              </div>
            </div>

            <div className="add-member-form-btn-wrapper subscriptions-subscribe-btns">
              <button className="btn-style blank-btn add-popup-cancel" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="btn-style confirm-modal-btn company company-btn"
                onClick={handleSubmit}
                disabled={subscribeLoading}>
                {subscribeLoading ? <Loading /> : "Subscribe"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmDeleteModal
        open={isOpenDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleDelete}
        text="Are you sure delete this payment method?"
        isLoading={isDeleteLoading}
      />
    </>
  );
};

export default SubscriptionModal;
