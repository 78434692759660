import DocumentUploadBig from "../../assets/images/run-payroll/document-upload-big.png";

const RunPayrollDropzone = ({ handleUploadFile }) => {
  return (
    <div className="run-payroll-file-upload-main">
      <input type="file" id="file-uploader" accept=".csv" onChange={handleUploadFile} multiple />
      <img src={DocumentUploadBig} alt="" />
      <h6>
        Drag & Drop or <strong>Choose file</strong> to upload
      </h6>
      <h6>
        CSV format only
      </h6>
    </div>
  );
};

export default RunPayrollDropzone;
